import {
  ChangeDetectionStrategy,
  Component,
  computed,
  DestroyRef,
  Input,
  OnChanges,
  Signal,
  signal,
  SimpleChanges,
  TemplateRef,
} from "@angular/core";
import {
  StatListTableRow,
  TopicExercise,
  TopicSummary,
} from "./interfaces/stat-list-table-row";
import { StudentStatListTableColumn } from "./enums/student-stat-list-table-column";
import { RowsExpander } from "src/app/shared/utils/rows-expander";
import {
  StudentStatElement,
  StudentStatElementTopic,
} from "../../interfaces/student";
import { AnswerType } from "../../types/answer-type";
import { Guid } from "../../types/guid";
import { Nillable } from "../../types/nillable";
import {
  Config,
  CourseCriteria,
  PartialGradesConfig,
  StudentPartialGrade,
  UserStatistics,
} from "../../interfaces/student-pass-criteria-api";
import { PassCriteriaType } from "../../enums/pass-criteria-type";
import { StatsService } from "../../services/statistics/stats.service";
import { Observable } from "rxjs";
import * as moment from "moment";
import { tap } from "rxjs/operators";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { ModalService } from "../../services/modal/modal.service";
import { FlaggedExercisesModalComponent } from "src/app/modules/student/components/flagged-exercises-modal/flagged-exercises-modal.component";
import { FlaggedExercise } from "../../interfaces/flagged-exercises";
import { MappedHelpRequests } from "../../interfaces/mapped-help-requests";
import { SimpleName } from "../../interfaces/orgadmin/simple-name";

@Component({
  selector: "student-stat-list-table",
  templateUrl: "./student-stat-list-table.component.html",
  styleUrls: ["./student-stat-list-table.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StudentStatListTableComponent implements OnChanges {
  @Input() courseId: Nillable<Guid>;
  @Input() headerTemplateRef: Nillable<TemplateRef<void>>;

  @Input() statistics: StudentStatElement[] = [];
  @Input() showUserBy: Nillable<StudentStatListTableColumn> =
    StudentStatListTableColumn.NAME;
  @Input() flaggedExercisesHistory: FlaggedExercise[];

  @Input() isTeacher: boolean;
  @Input() criteria: Nillable<CourseCriteria>;
  @Input() partialGrades: Nillable<PartialGradesConfig>;

  @Input() isOrgAdmin: boolean = false;

  @Input() set filterValue(value: string) {
    this._searchQuery.set(value);
  }
  private _searchQuery = signal("");

  currentPartialGrade$: Observable<string> = this.stats.currentPartialGradeId;
  selectedExercises$: Observable<string[]> = this.stats.currentItems;
  selectableExercises$: Observable<boolean> = this.stats.selectableFlag;
  highlightableExercises$: Observable<boolean> =
    this.stats.highlightableExercises;
  currentEnabledExercises$: Observable<string[]> =
    this.stats.currentEnabledExercises;

  topics: StudentStatElementTopic[] = [];
  AnswerType = AnswerType;
  PassCriteriaType = PassCriteriaType;
  rowsExpander = new RowsExpander();
  gradesHistoryIsOpen: boolean = false;
  currentGradeId: string;
  currentUserId: string;

  gradeOnePartialGrade: boolean;
  gradeOneWholeCourse: boolean;

  users = signal<SimpleName[]>([]);
  usersGrades = signal<UserStatistics[]>([]);
  rows = signal<StatListTableRow[]>([]);

  filteredUsers: Signal<SimpleName[]> = computed(() =>
    this.users().filter((stat) =>
      stat.name.toLowerCase().includes(this._searchQuery().toLowerCase()),
    ),
  );

  filteredUsersWithGrade: Signal<UserStatistics[]> = computed(() =>
    this.usersGrades().filter((stat) =>
      stat.name.toLowerCase().includes(this._searchQuery().toLowerCase()),
    ),
  );

  filteredStatistics: Signal<StudentStatElement[]> = computed(() => {
    const query = this._searchQuery().toLowerCase().trim();

    return this.statistics.filter((stat) => {
      const fieldToFilter =
        this.showUserBy === StudentStatListTableColumn.NAME
          ? stat.name
          : stat.email;

      return query === "" || fieldToFilter.toLowerCase().includes(query);
    });
  });

  filteredRows: Signal<StatListTableRow[]> = computed(() => {
    const userIds = this.filteredUsers().map((user) => user.id);

    return this.rows().map((row) => {
      const userIdToIndex = new Map(
        this.users().map((user, index) => [user.id, index]),
      );

      return {
        ...row,
        topicSummary: userIds
          .map((userId) => {
            const originalIndex = userIdToIndex.get(userId);
            return originalIndex !== undefined
              ? row.topicSummary[originalIndex]
              : null;
          })
          .filter((summary) => summary !== null),

        exercises: row.exercises.map((exercise) => ({
          ...exercise,
          exerciseSummary: userIds
            .map((userId) => {
              const originalIndex = userIdToIndex.get(userId);
              return originalIndex !== undefined
                ? exercise.exerciseSummary[originalIndex]
                : null;
            })
            .filter((summary) => summary !== null),
        })),
      };
    });
  });

  constructor(
    private stats: StatsService,
    private destroyRef: DestroyRef,
    private modal: ModalService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    const { statistics, showUserBy, criteria, partialGrades } = changes;

    if (statistics) {
      this.configureTopics();
      this.rowsExpander.configure(this.topics);

      this.users.set(this.getTableColumns());
      this.rows.set(this.getTableRows());
    }

    if (showUserBy) {
      this.users.set(this.getTableColumns());
      this.usersGrades.set(this.getGradesForStudents());
    }

    if (
      criteria &&
      (criteria.currentValue?.criteria_type == PassCriteriaType.THRESHOLD ||
        criteria.currentValue?.criteria_type == PassCriteriaType.GRADES)
    ) {
      this.usersGrades.set(this.getGradesForStudents());
    }

    if (criteria?.currentValue?.criteria_type === PassCriteriaType.GRADES) {
      this.checkLowestGrade();
    }

    if (partialGrades?.currentValue) {
      this.checkLowestGradeWholeCourse();
    }

    this.highlightableExercises$
      .pipe(
        tap((selectable) => {
          if (selectable && !this.rowsExpander.isAllExpanded) {
            this.rowsExpander.toggleExpandAllState();
          }
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  private getTableColumns(): { id: string; name: string }[] {
    return this.statistics?.map((statistic: StudentStatElement) => ({
      id: statistic.id,
      name: statistic[this.showUserBy],
    }));
  }

  private getTableRows(): StatListTableRow[] {
    return this.topics.map((topic: StudentStatElementTopic) => ({
      name: topic.topic_name,
      topicSummary: this.getTopicSummary(topic),
      exercises: this.getTopicExerciseListWithSummary(topic),
    }));
  }

  private getGradesForStudents(): any {
    if (!this.criteria?.student_grades) {
      return;
    }

    return this.users().map((user) => ({
      ...user,
      grade:
        this.criteria?.student_grades.find((grade) => grade.user === user.id) ||
        null,
    }));
  }

  getGrades(
    config: Config,
    column: { id: string; name: string },
  ): StudentPartialGrade[] {
    return config.grades
      .filter((grade) => grade.user === column.id)
      .map((grade) => ({
        ...grade,
        created_at: moment(grade.created_at).format("DD-MM-YYYY"),
      }));
  }

  getLastGrade(grades: StudentPartialGrade[]): StudentPartialGrade | null {
    return grades.length ? grades[grades.length - 1] : null;
  }

  checkLowestGrade(): void {
    this.gradeOneWholeCourse = this.criteria.grades[0].grade === 1;
  }

  checkLowestGradeWholeCourse(): void {
    this.gradeOnePartialGrade = this.partialGrades.grades[0].grade === 1;
  }

  private getTopicSummary(topic: StudentStatElementTopic): TopicSummary[] {
    return this.statistics.map((statistic) => {
      const found = statistic.topics.find(
        (innerTopic) => innerTopic.topic_name === topic.topic_name,
      );

      return {
        completed: found.completed,
        all: found.exercise_count,
      };
    });
  }

  private getTopicExerciseListWithSummary(
    topic: StudentStatElementTopic,
  ): TopicExercise[] {
    return topic.exercises.map((exercise) => ({
      name: exercise.name,
      exerciseSummary: this.statistics.map((statistic) => {
        const found = statistic.topics.find(
          (innerTopic) => innerTopic.topic_name === topic.topic_name,
        );
        const foundExercise = found.exercises.find(
          (innerExercise) => innerExercise.name === exercise.name,
        );

        return {
          exercise: {
            id: foundExercise.id,
            answer_type: foundExercise.answer_type,
            name: foundExercise.name,
          },
          points: foundExercise.points,
          maxPoints: foundExercise.max_points,
          passedTime: foundExercise.passed_at,
        };
      }),
    }));
  }

  private configureTopics(): void {
    const [first] = this.statistics ?? [];

    this.topics = first?.topics ?? [];
  }

  handleSetExercises(exercise: TopicExercise, configId: string): void {
    const exerciseId = exercise.exerciseSummary[0].exercise?.id;
    this.stats.toggleItem(exerciseId);
  }

  isSelectedExercise(
    exercise: TopicExercise,
    selectedExercises: string[],
  ): boolean {
    const exerciseId = exercise.exerciseSummary[0].exercise?.id;

    return selectedExercises.includes(exerciseId);
  }

  isEnableExercise(
    exercise: TopicExercise,
    enabledExercises: string[],
  ): boolean {
    const exerciseId = exercise.exerciseSummary[0].exercise?.id;

    if (enabledExercises.length === 0) {
      return true;
    }

    return enabledExercises.includes(exerciseId);
  }

  toggleGradesHistory(gradeId: string, userId: string): void {
    if (this.currentGradeId === gradeId && this.currentUserId === userId) {
      this.gradesHistoryIsOpen = !this.gradesHistoryIsOpen;
    } else {
      this.gradesHistoryIsOpen = true;
      this.currentGradeId = gradeId;
      this.currentUserId = userId;
    }
  }

  handleOpenCourseHistory(row: StatListTableRow): void {
    const results = this.mappedHelpRequests(row, this.flaggedExercisesHistory);

    this.modal.showModal(
      {
        modalTitle: row.name,
        data: results,
      },
      FlaggedExercisesModalComponent,
      "FLAGGED_EXERCISES_MODAL",
    );
  }

  handleOpenExerciseHistory(row: TopicExercise): void {
    const helpRequested = this.flaggedExercisesHistory.filter(
      (exercise) => exercise.exercise === row.exerciseSummary[0].exercise.id,
    );

    const result = {
      exercise: {
        id: row.exerciseSummary[0].exercise.id,
        name: row.exerciseSummary[0].exercise.name,
      },
      help_requested: helpRequested,
    };

    this.modal.showModal(
      {
        data: [result],
      },
      FlaggedExercisesModalComponent,
      "FLAGGED_EXERCISES_MODAL",
    );
  }

  mappedHelpRequests(
    topic: StatListTableRow,
    help_requested: FlaggedExercise[],
  ): MappedHelpRequests[] {
    const mappedHelpRequests: MappedHelpRequests[] = [];

    topic.exercises.forEach(({ exerciseSummary }) => {
      if (!exerciseSummary.length) return;

      const { id: exerciseId, name } = exerciseSummary[0].exercise;

      const filteredExercises = help_requested.filter(
        ({ exercise }) => exercise === exerciseId,
      );

      if (filteredExercises.length) {
        mappedHelpRequests.push({
          exercise: { id: exerciseId, name },
          help_requested: filteredExercises,
        });
      }
    });

    return mappedHelpRequests;
  }

  calculateTotalRequestsInTopic(topic: StatListTableRow): number {
    return this.mappedHelpRequests(topic, this.flaggedExercisesHistory).reduce(
      (total, exercise) => total + exercise.help_requested.length,
      0,
    );
  }

  calculateTotalRequestsInExercise(row: TopicExercise): number {
    const helpRequested = this.flaggedExercisesHistory.filter(
      (exercise) => exercise.exercise === row.exerciseSummary[0].exercise.id,
    );

    return helpRequested.length;
  }
}
