<form [formGroup]="form">
  <div class="row justify-content-center">
    <div class="col-8">
      <dropdown
        mask="999"
        [suffix]="suffix"
        [placeholder]="'COURSES.COURSE.PASS_COURSE_FROM' | translate"
        [editable]="true"
        [formControl]="form.controls.passFrom"
        [options]="passSelectItems$ | async"
      />

      <validation-text textAlign="right" [control]="form.controls.passFrom">
        <div class="my-2">{{ "GLOBAL.VALUE_IS_TOO_LARGE" | translate }}</div>
      </validation-text>
    </div>
  </div>

  <div class="criteria mt-5">
    <criteria-bar
      [criteria]="mappedCriterias[0]"
      [isEditable]="(currentRule$ | async) === mappedCriterias[0]"
      [requiredExercises]="mappedCriterias[1].exercises"
      [isDisabled]="
        form.controls.passFrom.invalid ||
        (isCriteriaEditable &&
          !((currentRule$ | async) === mappedCriterias[0].name))
      "
      (editableChanged)="onEditableChanged($event, mappedCriterias[0].name)"
      (criteriaValues)="onCriteriaData($event)"
    />

    <criteria-bar
      [criteria]="mappedCriterias[1]"
      [isEditable]="(currentRule$ | async) === mappedCriterias[1]"
      [selectedExercises]="mappedCriterias[0].exercises"
      [isDisabled]="
        form.controls.passFrom.invalid ||
        (isCriteriaEditable &&
          !((currentRule$ | async) === mappedCriterias[1].name))
      "
      (editableChanged)="onEditableChanged($event, mappedCriterias[1].name)"
      (criteriaValues)="onCriteriaData($event)"
    />
  </div>

  <div class="d-flex justify-content-center mt-5">
    <button
      type="button"
      class="button btn-primary-900 px-4"
      [disabled]="form.invalid || isCriteriaEditable"
      (click)="handleSaveClick()"
    >
      {{ "GLOBAL.SAVE" | translate }}
    </button>
  </div>
</form>
