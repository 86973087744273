import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { StatsService } from "../../services/statistics/stats.service";
import { Observable } from "rxjs";
import { CriteriaBarPayload } from "../../interfaces/student-pass-criteria-api";
import { ModalService } from "../../services/modal/modal.service";
import { ConfirmationModalComponent } from "../modals/confirmation-modal/confirmation-modal.component";
import { TranslateService } from "@ngx-translate/core";
import { CRITERIA_TITLES_TEACHER } from "../../consts/stats-criteria-titles";

@Component({
  selector: "criteria-bar",
  templateUrl: "./criteria-bar.component.html",
  styleUrls: ["./criteria-bar.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CriteriaBarComponent implements OnInit {
  @Input() criteria: CriteriaBarPayload;
  @Input() selectedExercises: string[];
  @Input() requiredExercises: string[];
  @Input() isEditable: boolean;
  @Input() isDisabled: boolean = false;

  @Output() criteriaValues = new EventEmitter<CriteriaBarPayload>();
  @Output() editableChanged = new EventEmitter<boolean>();

  currentPartialGradeId$: Observable<string> = this.stats.currentPartialGradeId;
  selectedExercises$: Observable<string[]> = this.stats.currentItems;

  displayExercises: boolean = false;
  form: FormGroup = this.createForm();
  readonly criteriaTitles = CRITERIA_TITLES_TEACHER;

  localExercises: string[] = [];
  localRequiredExercises: string[] = [];

  get is_active(): FormControl {
    return this.form.controls.is_active as FormControl<boolean>;
  }

  get exercises(): FormControl {
    return this.form.controls.exercises as FormControl<boolean>;
  }

  constructor(
    private fb: FormBuilder,
    private stats: StatsService,
    private modal: ModalService,
    private translate: TranslateService,
  ) {}

  ngOnInit(): void {
    const isRangeAssessment = this.criteria.name === this.criteriaTitles[0];
    const isMandatory = this.criteria.name === this.criteriaTitles[1];

    if (isRangeAssessment || isMandatory) {
      this.localExercises = isRangeAssessment
        ? this.criteria.exercises
        : this.selectedExercises;
      this.localRequiredExercises = isRangeAssessment
        ? this.requiredExercises
        : this.criteria.exercises;
    }

    const hasExercises = this.criteria?.exercises?.length > 0;
    this.is_active.setValue(hasExercises);

    this.is_active.valueChanges.subscribe((isActive) => {
      this.isEditable = !!isActive;
      this.editableChanged.emit(this.isEditable);
      this.stats.changeSelectable(this.isEditable);
      this.stats.setEnabledExercises(
        isActive && isMandatory ? this.selectedExercises : [],
      );
      this.stats.changeHighlightable(isActive);

      if (this.isEditable) {
        this.stats.clearExercises();
      } else {
        if (isRangeAssessment || isMandatory) {
          this.criteriaValues.emit({
            name: this.criteria.name,
            exercises: isRangeAssessment ? [] : this.selectedExercises,
            required_exercises: isRangeAssessment ? this.requiredExercises : [],
          });
        }
      }
    });
  }

  createForm(): FormGroup {
    return this.fb.group({
      is_active: this.fb.control<boolean>(false),
      exercises: this.fb.control<string[]>([]),
    });
  }

  save(value: boolean, exercises: string[]): void {
    this.isEditable = value;
    this.editableChanged.emit(this.isEditable);
    this.stats.changeSelectable(this.isEditable);
    this.stats.changeHighlightable(false);

    const isRangeAssessment = this.criteria.name === this.criteriaTitles[0];
    const isMandatory = this.criteria.name === this.criteriaTitles[1];

    this.stats.setEnabledExercises(
      value && isMandatory ? this.selectedExercises : [],
    );

    if (value) {
      this.stats.clearExercises();
      this.stats.changeHighlightable(true);

      if (this.criteria.name == this.criteriaTitles[0]) {
        this.localExercises.forEach((exercise) => this.stats.addItem(exercise));
      }

      if (this.criteria.name == this.criteriaTitles[1]) {
        this.localRequiredExercises.forEach((exercise) =>
          this.stats.addItem(exercise),
        );
      }
    } else {
      if (isRangeAssessment) {
        this.localExercises = exercises;
      } else if (isMandatory) {
        this.localRequiredExercises = exercises;
      }

      this.criteriaValues.emit({
        name: this.criteria.name,
        exercises: isMandatory ? this.selectedExercises : this.localExercises,
        required_exercises: this.localRequiredExercises,
      });
    }
  }

  handleDisplayModalError(): void {
    this.modal.showModal(
      {
        modalTitle: "COURSES.COURSE.INCORRECT_SCOPE",
        message: "COURSES.COURSE.INCORRECT_SCOPE_DESC",
        cancelButtonHide: true,
        confirmButtonText: "GROUPS.ADD.BACK_BUTTON",
        confirmButtonColor: "primary",
        icon: "error",
      },
      ConfirmationModalComponent,
      "MANDATORY_EXERCISES_FAIL",
    ) as ConfirmationModalComponent;
  }

  cancel(value: boolean): void {
    if (!this.criteria.exercises.length) {
      this.is_active.setValue(false);
    }

    if (!value) {
      this.isEditable = false;
      this.editableChanged.emit(false);
      this.stats.changeSelectable(false);
      this.stats.changeHighlightable(false);
    }
  }

  toggleEditable(value: boolean): void {
    this.isEditable = value;

    if (this.isEditable) {
      this.is_active.disable();
    } else {
      this.is_active.enable();
    }
    this.editableChanged.emit(value);
  }

  view(criteria: string): void {
    const isSameCriteria = this.criteria.name === criteria;

    this.displayExercises = !isSameCriteria;
    this.stats.setCurrentPartialGrade(
      isSameCriteria ? null : this.criteria.name,
    );
    this.stats.changeHighlightable(this.displayExercises);
    this.stats.clearExercises();

    if (this.displayExercises) {
      const exercisesMap = {
        [this.criteriaTitles[0]]: this.localExercises,
        [this.criteriaTitles[1]]: this.localRequiredExercises,
      };

      const selectedExercises = exercisesMap[this.criteria.name];

      if (selectedExercises) {
        selectedExercises.forEach((exercise) => this.stats.addItem(exercise));
      }
    }
  }

  checkExercises(requiredExercises: string[], exercises: string[]): boolean {
    if (!requiredExercises) return true;

    const missingExercises = requiredExercises.filter(
      (id) => !exercises.includes(id),
    );

    if (this.criteria.name !== "COURSES.COURSE.RANGE_ASSESSMENT") {
      return true;
    }

    if (missingExercises.length > 0) {
      return false;
    }

    return true;
  }
}
