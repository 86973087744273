import { createReducer, on } from "@ngrx/store";
import { HttpErrorResponse } from "@angular/common/http";
import {
  deletePartialGradesLoad,
  deletePartialGradesLoadFailure,
  deletePartialGradesLoadSuccess,
} from "../../actions/partial-grades/delete-partial-grade.actions";

export interface DeletePartialGradeState {
  error: HttpErrorResponse | null;
}

const initialState: DeletePartialGradeState = {
  error: null,
};

export const deletePartialGradeReducer = createReducer(
  initialState,
  on(
    deletePartialGradesLoad,
    (state): DeletePartialGradeState => ({
      ...state,
      error: null,
    }),
  ),
  on(
    deletePartialGradesLoadSuccess,
    (state): DeletePartialGradeState => ({
      ...state,
      error: null,
    }),
  ),
  on(deletePartialGradesLoadFailure, (state, { error }) => ({
    ...state,
    error: error,
  })),
);
