<div class="course-topic-exercises">
  <div class="w-75">
    <h3 class="course-topic-exercises-header">
      {{ headerLabel | translate }}
    </h3>

    <ng-container *ngIf="subtitleLabel">
      <p class="course-topic-exercises-subtitle">
        {{ subtitleLabel | translate }}
      </p>
    </ng-container>
  </div>

  <div *ngIf="isTeacher && groupId" class="course-topic-exercises-actions">
    <ng-container *ngIf="{ bulk: bulkInProgress$ | async } as topicHeader">
      <ng-container *ngIf="!isEmpty">
        <div class="course-topic-exercises-actions-label">
          {{ "COURSES.COURSE.APPLY_TO_ALL" | translate }}
        </div>
      </ng-container>

      <div class="course-topic-exercises-actions-container">
        <div
          [ngClass]="{
            'tutorial-icon':
              (tutorial$ | async) === TutorialTeacherSteps.COURSE_ACTIONS_BTN &&
              isTeacher &&
              anyHasHelp
          }"
          class="course-topic-action"
        >
          <switch-button
            *ngIf="anyHasHelp"
            tooltip="COURSES.COURSE.BULK.SELECT_UNSELECT_HELP"
            set="uil"
            icon="question-circle"
            class="switch-button-dark"
            [toggled]="allHelpTurnedOn"
            [busy]="
              topicHeader.bulk.section === section &&
              topicHeader.bulk.enabled_help
            "
            (clicked)="selectUnselect('enabled_help', allHelpTurnedOn)"
          />
        </div>

        <div
          [ngClass]="{
            'tutorial-icon':
              (tutorial$ | async) === TutorialTeacherSteps.COURSE_ACTIONS_BTN &&
              isTeacher &&
              anyHasVideo
          }"
          class="course-topic-action"
        >
          <switch-button
            *ngIf="anyHasVideo"
            tooltip="COURSES.COURSE.BULK.SELECT_UNSELECT_VIDEO"
            set="uil"
            icon="video-question"
            class="switch-button-dark"
            [toggled]="allVideoTurnedOn"
            [busy]="
              topicHeader.bulk.section === section &&
              topicHeader.bulk.enabled_video
            "
            (clicked)="selectUnselect('enabled_video', allVideoTurnedOn)"
          />
        </div>

        <div
          [ngClass]="{
            'tutorial-icon':
              (tutorial$ | async) === TutorialTeacherSteps.COURSE_ACTIONS_BTN &&
              isTeacher &&
              exercises.length > 0
          }"
          class="course-topic-action"
        >
          <switch-button
            *ngIf="exercises.length > 0"
            tooltip="COURSES.COURSE.BULK.SELECT_UNSELECT"
            icon="eye"
            class="switch-button-dark"
            [toggled]="allIncluded"
            [busy]="
              topicHeader.bulk.section === section && topicHeader.bulk.selected
            "
            (clicked)="selectUnselect('selected', allIncluded, true)"
          />
        </div>
      </div>
    </ng-container>
  </div>
</div>

<ul listbox>
  <li listbox-element *ngFor="let exercise of exercises; trackBy: guid">
    <course-exercise-listbox-element-content
      #exerciseRef
      [exercise]="exercise"
      [teacher]="isTeacher"
      [emitters]="emitters"
      [courseId]="courseId"
      [activeGroup]="groupId"
      [topicId]="currentTopic.id"
      [ngStyle]="{ order: order[exercise.id] }"
      [quizExerciseTriggered]="quizExerciseTriggered"
      [studentsList]="studentsList"
    />
  </li>
</ul>
