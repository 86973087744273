import { HttpErrorResponse } from "@angular/common/http";
import { createAction, props } from "@ngrx/store";

export const prefix = "[Destroy Partial Grades]";

const destroyPartialGradesActions = {
  request: `${prefix} Load`,
  requestActiveSuccess: `${prefix} Load success`,
  requestFail: `${prefix} Load fail`,
};

export const destroyPartialGradesLoad = createAction(
  destroyPartialGradesActions.request,
  props<{
    CourseId: string;
    GroupId: string;
  }>(),
);

export const destroyPartialGradesLoadSuccess = createAction(
  destroyPartialGradesActions.requestActiveSuccess,
);

export const destroyPartialGradesLoadFailure = createAction(
  destroyPartialGradesActions.requestFail,
  props<{ error: HttpErrorResponse }>(),
);
