import { HttpErrorResponse } from "@angular/common/http";
import { createAction, props } from "@ngrx/store";
import { Config } from "src/app/shared/interfaces/student-pass-criteria-api";

export const prefix = "[Calculate Partial Grades]";

const calculatePartialGradesActions = {
  request: `${prefix} Load`,
  requestActiveSuccess: `${prefix} Load success`,
  requestFail: `${prefix} Load fail`,
};

export const calculatePartialGradesLoad = createAction(
  calculatePartialGradesActions.request,
  props<{
    CourseId: string;
    GroupId: string;
  }>(),
);

export const calculatePartialGradesLoadSuccess = createAction(
  calculatePartialGradesActions.requestActiveSuccess,
  props<{ config: Config[] }>(),
);

export const calculatePartialGradesLoadFailure = createAction(
  calculatePartialGradesActions.requestFail,
  props<{ error: HttpErrorResponse }>(),
);
