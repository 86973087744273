import { createReducer, on } from "@ngrx/store";
import { HttpErrorResponse } from "@angular/common/http";
import { PartialGradesConfig } from "src/app/shared/interfaces/student-pass-criteria-api";
import {
  getStudentPartialGradesLoad,
  getStudentPartialGradesLoadFailure,
  getStudentPartialGradesLoadSuccess,
} from "../../actions/partial-grades/get-student-partial-grades.actions";

export interface GetStudentPartialGradesState {
  config: PartialGradesConfig;
  error: HttpErrorResponse | null;
}

const initialState: GetStudentPartialGradesState = {
  config: null,
  error: null,
};

export const getStudentPartialGradesReducer = createReducer(
  initialState,
  on(
    getStudentPartialGradesLoad,
    (state): GetStudentPartialGradesState => ({
      ...state,
      config: null,
      error: null,
    }),
  ),
  on(
    getStudentPartialGradesLoadSuccess,
    (state, action): GetStudentPartialGradesState => ({
      ...state,
      config: action.config,
      error: null,
    }),
  ),
  on(getStudentPartialGradesLoadFailure, (state, { error }) => ({
    ...state,
    error: error,
  })),
);
