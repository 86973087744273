import { Component, Input } from "@angular/core";

@Component({
  selector: "students-count-badge",
  templateUrl: "./students-count-badge.component.html",
  styleUrls: ["./students-count-badge.component.scss"],
})
export class StudentsCountBadgeComponent {
  @Input() count: string;
  @Input() color: string = "hlogo";
  @Input() hover: boolean = false;
}
