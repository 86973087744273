import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, concatMap, map, mergeMap } from "rxjs/operators";
import { PartialGradesService } from "src/app/shared/services/partial-grades/partial-grades.service";
import {
  enablePartialGradesLoad,
  enablePartialGradesLoadFailure,
  enablePartialGradesLoadSuccess,
} from "../../actions/partial-grades/enable-partial-grades.actions";
import {
  destroyPartialGradesLoad,
  destroyPartialGradesLoadFailure,
  destroyPartialGradesLoadSuccess,
} from "../../actions/partial-grades/destroy-partial-grades.actions";
import {
  getPartialGradesLoad,
  getPartialGradesLoadFailure,
  getPartialGradesLoadSuccess,
} from "../../actions/partial-grades/get-partial-grades.actions";
import {
  Config,
  GarlicConfig,
  PartialGradesConfig,
} from "src/app/shared/interfaces/student-pass-criteria-api";
import {
  calculatePartialGradesLoad,
  calculatePartialGradesLoadFailure,
  calculatePartialGradesLoadSuccess,
} from "../../actions/partial-grades/calculate-partial-grades.actions";
import {
  createPartialGradesLoad,
  createPartialGradesLoadFailure,
  createPartialGradesLoadSuccess,
} from "../../actions/partial-grades/create-partial-grade.actions";
import {
  editPartialGradesLoad,
  editPartialGradesLoadFailure,
  editPartialGradesLoadSuccess,
} from "../../actions/partial-grades/edit-partial-grade.actions";
import {
  deletePartialGradesLoad,
  deletePartialGradesLoadFailure,
  deletePartialGradesLoadSuccess,
} from "../../actions/partial-grades/delete-partial-grade.actions";
import {
  getStudentPartialGradesLoad,
  getStudentPartialGradesLoadFailure,
  getStudentPartialGradesLoadSuccess,
} from "../../actions/partial-grades/get-student-partial-grades.actions";

@Injectable()
export class SetPartialGradesEffect {
  enablePartialGradesConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(enablePartialGradesLoad),
      concatMap((param) => {
        return this.partialGradesService
          .enablePartialGrades(param.CourseId, param.GroupId, param.grades)
          .pipe(
            map((res: GarlicConfig) =>
              enablePartialGradesLoadSuccess({ config: res }),
            ),
            catchError(({ error }) =>
              of(enablePartialGradesLoadFailure(error)),
            ),
          );
      }),
    ),
  );

  destroyPartialGradesConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(destroyPartialGradesLoad),
      concatMap((param) => {
        return this.partialGradesService
          .destroyPartialGrades(param.CourseId, param.GroupId)
          .pipe(
            mergeMap((res: Config[]) => [
              destroyPartialGradesLoadSuccess(),
              getPartialGradesLoad({
                CourseId: param.CourseId,
                GroupId: param.GroupId,
              }),
            ]),
            catchError(({ error }) =>
              of(destroyPartialGradesLoadFailure(error)),
            ),
          );
      }),
    ),
  );

  getPartialGradesConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getPartialGradesLoad),
      concatMap((param) => {
        return this.partialGradesService
          .getPartialGrades(param.CourseId, param.GroupId)
          .pipe(
            map((res: PartialGradesConfig) =>
              getPartialGradesLoadSuccess({ config: res }),
            ),
            catchError(({ error }) => of(getPartialGradesLoadFailure(error))),
          );
      }),
    ),
  );

  getStudentPartialGradesConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getStudentPartialGradesLoad),
      concatMap((param) => {
        return this.partialGradesService
          .getStudentPartialGrades(param.CourseId, param.GroupId)
          .pipe(
            map((res: PartialGradesConfig) =>
              getStudentPartialGradesLoadSuccess({ config: res }),
            ),
            catchError(({ error }) =>
              of(getStudentPartialGradesLoadFailure(error)),
            ),
          );
      }),
    ),
  );

  calculatePartialGradesConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(calculatePartialGradesLoad),
      concatMap((param) => {
        return this.partialGradesService
          .calculatePartialGrades(param.CourseId, param.GroupId)
          .pipe(
            mergeMap((res: Config[]) => [
              calculatePartialGradesLoadSuccess({ config: res }),
              getPartialGradesLoad({
                CourseId: param.CourseId,
                GroupId: param.GroupId,
              }),
            ]),
            catchError(({ error }) =>
              of(calculatePartialGradesLoadFailure(error)),
            ),
          );
      }),
    ),
  );

  createPartialGradeConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createPartialGradesLoad),
      concatMap((param) => {
        return this.partialGradesService
          .createPartialGrade(
            param.CourseId,
            param.GroupId,
            param.PartialGradePayload,
          )
          .pipe(
            mergeMap((res: Config) => [
              createPartialGradesLoadSuccess({ config: res }),
              getPartialGradesLoad({
                CourseId: param.CourseId,
                GroupId: param.GroupId,
              }),
            ]),
            catchError(({ error }) =>
              of(createPartialGradesLoadFailure(error)),
            ),
          );
      }),
    ),
  );

  editPartialGradeConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(editPartialGradesLoad),
      concatMap((param) => {
        return this.partialGradesService
          .editPartialGrade(
            param.CourseId,
            param.GroupId,
            param.GarlicId,
            param.PartialGradePayload,
          )
          .pipe(
            mergeMap((res: Config) => [
              editPartialGradesLoadSuccess({ config: res }),
              getPartialGradesLoad({
                CourseId: param.CourseId,
                GroupId: param.GroupId,
              }),
            ]),
            catchError(({ error }) => of(editPartialGradesLoadFailure(error))),
          );
      }),
    ),
  );

  deletePartialGradeConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deletePartialGradesLoad),
      concatMap((param) => {
        return this.partialGradesService
          .deletePartialGrade(param.CourseId, param.GroupId, param.GarlicId)
          .pipe(
            mergeMap(() => [
              deletePartialGradesLoadSuccess(),
              getPartialGradesLoad({
                CourseId: param.CourseId,
                GroupId: param.GroupId,
              }),
            ]),
            catchError(({ error }) =>
              of(deletePartialGradesLoadFailure(error)),
            ),
          );
      }),
    ),
  );

  constructor(
    private partialGradesService: PartialGradesService,
    private actions$: Actions,
  ) {}
}
