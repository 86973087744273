import { createReducer, on } from "@ngrx/store";
import { HttpErrorResponse } from "@angular/common/http";
import { Config } from "src/app/shared/interfaces/student-pass-criteria-api";
import {
  createPartialGradesLoad,
  createPartialGradesLoadFailure,
  createPartialGradesLoadSuccess,
} from "../../actions/partial-grades/create-partial-grade.actions";

export interface CreatePartialGradeState {
  config: Config;
  error: HttpErrorResponse | null;
}

const initialState: CreatePartialGradeState = {
  config: null,
  error: null,
};

export const createPartialGradeReducer = createReducer(
  initialState,
  on(
    createPartialGradesLoad,
    (state): CreatePartialGradeState => ({
      ...state,
      config: null,
      error: null,
    }),
  ),
  on(
    createPartialGradesLoadSuccess,
    (state, action): CreatePartialGradeState => ({
      ...state,
      config: action.config,
      error: null,
    }),
  ),
  on(createPartialGradesLoadFailure, (state, { error }) => ({
    ...state,
    error: error,
  })),
);
