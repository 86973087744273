import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { StudentPassFilterFormData } from "../models/student-pass-filter-form-data";
import { Nillable } from "src/app/shared/types/nillable";
import { PassCriteriaType } from "src/app/shared/enums/pass-criteria-type";
import { CourseCriteria } from "src/app/shared/interfaces/student-pass-criteria-api";

@Injectable()
export class StudentPassConditionFormService {
  private readonly formData$ = new BehaviorSubject<
    Nillable<StudentPassFilterFormData>
  >(null);

  formData(): Observable<Nillable<StudentPassFilterFormData>> {
    return this.formData$.asObservable();
  }

  save(data: StudentPassFilterFormData): void {
    this.formData$.next(data);
  }

  get data(): Observable<Nillable<StudentPassFilterFormData>> {
    return this.formData$;
  }

  clear(): void {
    this.formData$.next(null);
  }

  buildCriteriaForApiUpdate(threshold: number): CourseCriteria {
    return {
      criteria_type: PassCriteriaType.THRESHOLD,
      threshold,
    };
  }

  updateCriteriaFromApiResponse(criteria: CourseCriteria) {
    this.save({ passFrom: criteria.threshold });
  }
}
