import { HttpErrorResponse } from "@angular/common/http";
import { createAction, props } from "@ngrx/store";
import { PartialGradesConfig } from "src/app/shared/interfaces/student-pass-criteria-api";

export const prefix = "[Get Partial Grades]";

const getPartialGradesActions = {
  request: `${prefix} Load`,
  requestActiveSuccess: `${prefix} Load success`,
  requestFail: `${prefix} Load fail`,
};

export const getPartialGradesLoad = createAction(
  getPartialGradesActions.request,
  props<{
    CourseId: string;
    GroupId: string;
  }>(),
);

export const getPartialGradesLoadSuccess = createAction(
  getPartialGradesActions.requestActiveSuccess,
  props<{ config: PartialGradesConfig }>(),
);

export const getPartialGradesLoadFailure = createAction(
  getPartialGradesActions.requestFail,
  props<{ error: HttpErrorResponse }>(),
);
