<ng-container *ngIf="!loader; else loading">
  <div
    *ngIf="{
      selected: selectedExercises$ | async,
      id: currentPartialGradeId$ | async,
      selectable: selectable$ | async
    } as exercisesObs"
    [formGroup]="form"
    class="bar-container d-flex justify-content-between"
  >
    <div *ngIf="!studentView" class="bar-container__grade-title pl-4">
      <div [class.disabled]="exercisesObs.selectable">
        <input
          type="checkbox"
          [id]="config ? config.id : 'form'"
          class="show"
          [checked]="is_active.value"
          formControlName="is_active"
          (change)="handleDisplayPartialGrade(is_active.value)"
        />
      </div>
      <div>
        <input
          type="text"
          class="input-title w-100"
          [placeholder]="'COURSES.COURSE.GRADE_PLACEHOLDER' | translate"
          formControlName="name"
          [readonly]="!isEditing"
        />
      </div>
    </div>
    <div *ngIf="studentView" class="bar-container__grade-title pl-4">
      <p class="bar-container__student-title">
        {{ config.name | translate }}
      </p>
    </div>

    <div
      class="bar-container__grade-exercises d-flex justify-content-center align-items-center"
    >
      <p class="exercises">
        {{ "COURSES.COURSE.EXERCISES" | translate }}:
        <ng-container *ngIf="creatorMode; else nonCreatorMode">
          {{ exercisesObs.selected.length }}
        </ng-container>
        <ng-template #nonCreatorMode>
          {{
            isEditing ? exercisesObs.selected.length : config.exercises.length
          }}
        </ng-template>
      </p>
    </div>
    <div
      class="bar-container__grade-buttons h-100 w-25"
      [ngClass]="{
        'bar-container__grade-buttons--unsaved': isEditing || creatorMode
      }"
    >
      <ng-container
        *ngIf="
          (isEditing && exercisesObs?.id === config?.id) || creatorMode;
          else editableGrade
        "
      >
        <button
          class="round-badge round-badge__accept d-flex justify-content-center align-items-center"
          tooltip="GROUPS.EDIT.EDIT_GROUP_BUTTON"
          (click)="acceptGrade()"
        >
          <span class="round-badge-label">
            <icon
              class="button-icon round-badge__accept-icon"
              set="uil"
              name="check"
            ></icon>
          </span>
        </button>
        <button
          class="round-badge round-badge__cancel d-flex justify-content-center align-items-center"
          tooltip="GLOBAL.CANCEL_BUTTON"
          (click)="cancelGrade()"
        >
          <span class="round-badge-label">
            <icon
              class="button-icon round-badge__cancel-icon"
              name="cancel-outline"
            ></icon>
          </span>
        </button>
      </ng-container>
    </div>

    <ng-template #editableGrade>
      <div
        *ngIf="!studentView"
        class="container-actions"
        [ngClass]="{ 'container-actions__edit': !studentView }"
      >
        <button
          type="button"
          [disabled]="exercisesObs.selectable"
          tooltip="ADMIN.EDIT"
          (click)="editGrade()"
          class="btn"
        >
          <icon class="button-icon" name="edit" set="uil" />
        </button>
      </div>

      <div
        class="container-actions"
        [ngClass]="{
          'action-pressed': displayExercises && exercisesObs.id === config.id
        }"
      >
        <button
          class="btn"
          type="button"
          [disabled]="exercisesObs.selectable"
          tooltip="COURSES.COURSE.PREVIEW_EXERCISES"
          (click)="viewExercises(exercisesObs.id, studentView)"
        >
          <icon
            class="button-icon"
            [ngClass]="{
              'button-icon__theme':
                displayExercises && exercisesObs.id === config.id,
              'action-pressed-student':
                displayExercises && exercisesObs.id === config.name
            }"
            name="eye"
            set="uil"
          />
        </button>
      </div>
    </ng-template>
  </div>
  <div
    *ngIf="form.invalid && (form.dirty || form.touched)"
    class="d-flex flex-column mini-label color-hinvalid validation-text font-weight-bold pt-1"
  >
    <span *ngIf="name.errors?.required" class="pt-1">
      {{ "GLOBAL.NAME_REQUIRED" | translate | uppercase }}
    </span>

    <span *ngIf="exercises.errors?.required" class="pt-1">
      {{ "COURSES.COURSE.EXERCISE_REQUIRED" | translate | uppercase }}
    </span>
  </div>
</ng-container>

<ng-template #loading>
  <div class="bar-container d-flex justify-content-center align-items-center">
    <loading-spinner></loading-spinner>
  </div>
</ng-template>
