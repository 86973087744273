import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { OrderByAvailabilityPipe } from "src/app/shared/pipes/order-by-availability.pipe";
import { Guid } from "src/app/shared/types/guid";
import { AnswerPayload } from "../../../interfaces/answer-payload";
import { Exercise } from "../../../interfaces/exercise";
import { ExerciseControl } from "../../../interfaces/exercise-control";
import { HelpRequest } from "../../../interfaces/help-request";
import { ExerciseSelectionParams } from "../../../interfaces/exercise-selection";
import * as fromCourse from "../../../../store/actions/course.actions";
import { Store } from "@ngrx/store";
import { CourseSelectorsState } from "../../../../store/selectors/course.selectors";
import { Topic } from "../../../interfaces/topic";
import { Observable } from "rxjs";
import { Nillable } from "../../../types/nillable";
import { ExerciseSection } from "../../../enums/exercise-section";
import { isEmpty, isNil } from "lodash-es";
import { TutorialTeacherSteps } from "src/app/shared/enums/tutorial-steps";
import * as fromTutorial from "src/app/store/reducers/tutorial.reducer";
import { ConfirmationModalComponent } from "../../modals/confirmation-modal/confirmation-modal.component";
import { ModalService } from "src/app/shared/services/modal/modal.service";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { CourseExerciseListboxElementContentComponent } from "../course-exercise-listbox-element-content/course-exercise-listbox-element-content.component";
import { StudentStatElement } from "src/app/shared/interfaces/student";

@Component({
  selector: "course-topic-exercises",
  templateUrl: "./course-topic-exercises.component.html",
  styleUrls: ["./course-topic-exercises.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CourseTopicExercisesComponent implements OnChanges {
  @Input() headerLabel: string = "COURSES.COURSE.EXERCISES";
  @Input() section: Nillable<ExerciseSection>;
  @Input() subtitleLabel: Nillable<string>;
  @Input() currentTopic: Topic;
  @Input() exercises: Exercise[] = [];
  @Input() courseId: Guid;
  @Input() groupId: Guid;
  @Input() studentsList: Array<StudentStatElement>;
  @Input("teacher") isTeacher = false;

  @Input()
  emitters: {
    [key: string]: EventEmitter<ExerciseControl | HelpRequest | AnswerPayload>;
  };

  @ViewChild("exerciseRef")
  exerciseContent!: CourseExerciseListboxElementContentComponent;
  order: Record<Guid, number> = {};

  bulkInProgress$: Observable<ExerciseSelectionParams> = this.store.select(
    (state) =>
      state[this.isTeacher ? "teacher" : "student"].singleCourse.currentTopic
        .bulkInProgress || {},
  );

  tutorial$: Observable<TutorialTeacherSteps> = this.store.select(
    (state) => state.tutorial.tutorial_step,
  );

  TutorialTeacherSteps = TutorialTeacherSteps;
  quizExerciseTriggered: boolean = false;

  private readonly destroyRef = inject(DestroyRef);

  constructor(
    private readonly store: Store<
      {
        tutorial: fromTutorial.TutorialState;
      } & CourseSelectorsState
    >,
    private readonly orderByAvailability: OrderByAvailabilityPipe,
    private readonly modal: ModalService,
  ) {}

  get isEmpty(): boolean {
    return isEmpty(this.exercises);
  }

  get allIncluded(): boolean {
    return (
      this.exercises.length &&
      this.exercises.every((exercise) => exercise.available)
    );
  }

  get allHelpTurnedOn(): boolean {
    return (
      this.exercises.filter((exercise) => exercise.available_help).length &&
      this.exercises
        .filter((exercise) => exercise.available_help)
        .every((exercise) => exercise.has_help)
    );
  }

  get allVideoTurnedOn(): boolean {
    return (
      this.exercises.filter((exercise) => exercise.available_video).length &&
      this.exercises
        .filter((exercise) => exercise.available_video)
        .every((exercise) => exercise.has_video)
    );
  }

  get anyHasHelp(): boolean {
    return this.exercises.some((exercise) => exercise.available_help);
  }

  get anyHasVideo(): boolean {
    return this.exercises.some((exercise) => exercise.available_video);
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { currentTopic } = changes;

    if (currentTopic) {
      this.order = this.orderTransformedByAvailability();
    }
  }

  guid(_index: number, exercise: Exercise): Guid {
    return exercise.id;
  }

  selectUnselect(
    param: keyof ExerciseSelectionParams,
    value: boolean,
    exerciseToggle: boolean = false,
  ): void {
    const courseIncludesQuizWithoutSettings = this.exercises.some(
      (exercise) =>
        !this.allIncluded &&
        exercise.quiz_properties &&
        isNil(exercise.quiz_properties.time_limit) &&
        isNil(exercise.quiz_properties.pass_mark) &&
        isNil(exercise.quiz_properties.max_attempts),
    );

    if (courseIncludesQuizWithoutSettings && exerciseToggle) {
      this.showReminderModal(param, value);
    } else {
      this.dispatchSelectUnselect(param, value);
    }
  }

  private showReminderModal(
    param: keyof ExerciseSelectionParams,
    value: boolean,
  ): void {
    const modal = this.modal.showModal(
      {
        modalTitle: "QUIZ.SETTINGS.REMINDER_TITLE",
        message: "QUIZ.SETTINGS.REMINDER_DESC",
        confirmButtonText: "QUIZ.EXERCISE.GO_TO_QUIZ",
        cancelButtonText: "GROUPS.ADD.SKIP_MODAL",
        confirmButtonColor: "primary",
        icon: "warning",
      },
      ConfirmationModalComponent,
      "BULK_QUIZ_SETTINGS_CONFIRMATION",
    ) as ConfirmationModalComponent;

    modal.onConfirm.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.quizExerciseTriggered = true;
      this.exerciseContent.toggle(true);
    });

    modal.onCancel.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.dispatchSelectUnselect(param, value);
    });
  }

  private dispatchSelectUnselect(
    param: keyof ExerciseSelectionParams,
    value: boolean,
  ): void {
    this.store.dispatch(
      new fromCourse.SelectUnselectAll({
        selection: {
          CourseId: this.courseId,
          GroupId: this.groupId,
          TopicId: this.currentTopic.id,
        },
        selectionParam: {
          [param]: !value,
          section: this.section ?? null,
        },
        selectionParamName: param,
      }),
    );
  }

  private orderTransformedByAvailability(): Record<Guid, number> {
    return this.orderByAvailability
      .transform(this.exercises.slice(), this.isTeacher)
      .reduce((order, exercise, index) => {
        return { ...order, [exercise.id]: index + 1 };
      }, {});
  }
}
