import { HttpErrorResponse } from "@angular/common/http";
import { createAction, props } from "@ngrx/store";
import {
  GarlicConfig,
  PartialGradesConfig,
} from "src/app/shared/interfaces/student-pass-criteria-api";

export const prefix = "[Enable Partial Grades]";

const enablePartialGradesActions = {
  request: `${prefix} Load`,
  requestActiveSuccess: `${prefix} Load success`,
  requestFail: `${prefix} Load fail`,
};

export const enablePartialGradesLoad = createAction(
  enablePartialGradesActions.request,
  props<{
    CourseId: string;
    GroupId: string;
    grades: any;
  }>(),
);

export const enablePartialGradesLoadSuccess = createAction(
  enablePartialGradesActions.requestActiveSuccess,
  props<{ config: GarlicConfig }>(),
);

export const enablePartialGradesLoadFailure = createAction(
  enablePartialGradesActions.requestFail,
  props<{ error: HttpErrorResponse }>(),
);
