import { createReducer, on } from "@ngrx/store";
import { HttpErrorResponse } from "@angular/common/http";
import { Config } from "src/app/shared/interfaces/student-pass-criteria-api";
import {
  editPartialGradesLoad,
  editPartialGradesLoadFailure,
  editPartialGradesLoadSuccess,
} from "../../actions/partial-grades/edit-partial-grade.actions";

export interface EditPartialGradeState {
  config: Config;
  error: HttpErrorResponse | null;
}

const initialState: EditPartialGradeState = {
  config: null,
  error: null,
};

export const editPartialGradeReducer = createReducer(
  initialState,
  on(
    editPartialGradesLoad,
    (state): EditPartialGradeState => ({
      ...state,
      config: null,
      error: null,
    }),
  ),
  on(
    editPartialGradesLoadSuccess,
    (state, action): EditPartialGradeState => ({
      ...state,
      config: action.config,
      error: null,
    }),
  ),
  on(editPartialGradesLoadFailure, (state, { error }) => ({
    ...state,
    error: error,
  })),
);
