import { HttpErrorResponse } from "@angular/common/http";
import { createAction, props } from "@ngrx/store";
import {
  Config,
  CriteriaBarPayload,
} from "src/app/shared/interfaces/student-pass-criteria-api";

export const prefix = "[Edit Partial Grades]";

const editPartialGradesActions = {
  request: `${prefix} Load`,
  requestActiveSuccess: `${prefix} Load success`,
  requestFail: `${prefix} Load fail`,
};

export const editPartialGradesLoad = createAction(
  editPartialGradesActions.request,
  props<{
    CourseId: string;
    GroupId: string;
    GarlicId: string;
    PartialGradePayload: CriteriaBarPayload;
  }>(),
);

export const editPartialGradesLoadSuccess = createAction(
  editPartialGradesActions.requestActiveSuccess,
  props<{ config: Config }>(),
);

export const editPartialGradesLoadFailure = createAction(
  editPartialGradesActions.requestFail,
  props<{ error: HttpErrorResponse }>(),
);
