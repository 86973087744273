import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from "@angular/core";
import { Guid } from "../../../types/guid";
import { AnswerType } from "../../../types/answer-type";
import { UploadedFileModel } from "../course-exercise-student-upload/interfaces/uploaded-file-model";
import { isNil } from "lodash-es";
import { AnswerResponse } from "../../../interfaces/answer-response";

export enum ExerciseUploadStatus {
  New,
  Loading,
  Sent,
  Passed,
  Failed,
}

@Component({
  selector: "div[exercise-file-upload-control-buttons]",
  templateUrl: "./exercise-file-upload-control-buttons.component.html",
  styleUrls: ["./exercise-file-upload-control-buttons.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExerciseFileUploadControlButtonsComponent {
  @Output() sendSolutionEvent = new EventEmitter<void>();
  @Output() showModalEvent = new EventEmitter<void>();
  @Output() downloadEvent = new EventEmitter<{ id: Guid; filename: string }>();
  @Output() cancelUploadEvent = new EventEmitter<UploadedFileModel>();

  @Input({ required: true }) courseId!: Guid;
  @Input({ required: true }) groupId!: Guid;
  @Input() isTeacher!: boolean;
  @Input() file?: UploadedFileModel;
  @Input() answers?: AnswerResponse[];
  @Input() count: number = 0;
  @Input() studentsList: number = 0;
  @Input() uploadProgress: number = 0;

  AnswerType = AnswerType;
  ExerciseUploadStatus = ExerciseUploadStatus;

  get status(): ExerciseUploadStatus {
    if (isNil(this.file)) {
      return ExerciseUploadStatus.New;
    }

    const { id, review, progress } = this.file;

    if (id && isNil(review) && isNil(progress)) {
      return ExerciseUploadStatus.Sent;
    }

    if (id && progress) {
      return ExerciseUploadStatus.Loading;
    }

    if (id && review === true && isNil(progress)) {
      return ExerciseUploadStatus.Passed;
    }

    if (id && review === false && isNil(progress)) {
      return ExerciseUploadStatus.Failed;
    }

    return ExerciseUploadStatus.New;
  }

  get isSendSolutionDisabled(): boolean {
    return isNil(this.file);
  }

  handleDownload(data: { id: Guid; filename: string }): void {
    this.downloadEvent.emit(data);
  }

  handleSendSolutionClick(): void {
    this.sendSolutionEvent.emit();
  }

  handleShowAnswerListModal(): void {
    this.showModalEvent.emit();
  }

  handleCancelUploadClick(): void {
    this.cancelUploadEvent.emit(this.file);
  }
}
