import { createReducer, on } from "@ngrx/store";
import { HttpErrorResponse } from "@angular/common/http";
import {
  enablePartialGradesLoad,
  enablePartialGradesLoadFailure,
  enablePartialGradesLoadSuccess,
} from "../../actions/partial-grades/enable-partial-grades.actions";
import { GarlicConfig } from "src/app/shared/interfaces/student-pass-criteria-api";

export interface EnablePartialGradesState {
  config: GarlicConfig;
  error: HttpErrorResponse | null;
}

const initialState: EnablePartialGradesState = {
  config: null,
  error: null,
};

export const enablePartialGradesReducer = createReducer(
  initialState,
  on(
    enablePartialGradesLoad,
    (state): EnablePartialGradesState => ({
      ...state,
      config: null,
      error: null,
    }),
  ),
  on(
    enablePartialGradesLoadSuccess,
    (state, action): EnablePartialGradesState => ({
      ...state,
      config: action.config,
      error: null,
    }),
  ),
  on(enablePartialGradesLoadFailure, (state, { error }) => ({
    ...state,
    error: error,
  })),
);
