import { createReducer, on } from "@ngrx/store";
import { HttpErrorResponse } from "@angular/common/http";
import { Config } from "src/app/shared/interfaces/student-pass-criteria-api";
import {
  calculatePartialGradesLoad,
  calculatePartialGradesLoadFailure,
  calculatePartialGradesLoadSuccess,
} from "../../actions/partial-grades/calculate-partial-grades.actions";

export interface CalculatePartialGradesState {
  config: Config[];
  error: HttpErrorResponse | null;
}

const initialState: CalculatePartialGradesState = {
  config: null,
  error: null,
};

export const calculatePartialGradesReducer = createReducer(
  initialState,
  on(
    calculatePartialGradesLoad,
    (state): CalculatePartialGradesState => ({
      ...state,
      config: null,
      error: null,
    }),
  ),
  on(
    calculatePartialGradesLoadSuccess,
    (state, action): CalculatePartialGradesState => ({
      ...state,
      config: action.config,
      error: null,
    }),
  ),
  on(
    calculatePartialGradesLoadFailure,
    (state, action): CalculatePartialGradesState => ({
      ...state,
      error: null,
    }),
  ),
);
