<div class="tabview">
  <div class="tabview-nav-content">
    <dropdown
      [placeholder]="'COURSES.COURSE.CHOOSE_CRITERIA' | translate"
      [formControl]="criteriaControl"
      [options]="criteriaOptions$ | async"
      (selectedOptionsEmitter)="handleTabClick($event)"
    />
  </div>

  <ng-content />
</div>
