<ng-container
  *ngIf="{
    isRunning: isRunning$ | async,
    exerciseStatus: exerciseProperitesStatus$ | async
  } as controlButtons"
>
  <quiz-history-button
    *ngIf="isTeacher || exercise.quiz_properties?.has_solutions"
    [courseId]="courseId"
    [teacher]="isTeacher"
    [exercise]="exercise"
  ></quiz-history-button>

  <button
    [disabled]="controlButtons.exerciseStatus == 'PENDING'"
    class="theme-hlogo exercise-control-button"
    type="button"
    (click)="start($event, controlButtons.isRunning)"
  >
    <countdown *ngIf="controlButtons.exerciseStatus == 'PENDING'"></countdown>
    {{ "QUIZ.EXERCISE.GO_TO_QUIZ" | translate }}
  </button>
</ng-container>
