<form
  *ngIf="{
    selected: selectedExercises$ | async,
    id: currentPartialGradeId$ | async
  } as exercises"
  [formGroup]="form"
  class="bar-container d-flex align-items-center"
  [class.disabled]="isDisabled"
>
  <div class="bar-container__header ml-3">
    <input
      type="checkbox"
      [ngClass]="{ disabled: isEditable }"
      formControlName="is_active"
      class="show"
    />
    <span class="bar-container__title">{{ criteria?.name | translate }}</span>
  </div>

  <hr *ngIf="is_active.value" class="h-100 bar-container__line" />

  <div *ngIf="is_active.value" class="bar-container__buttons">
    <ng-container *ngIf="isEditable; else noEditable">
      <confirm-buttons
        (saveEmitter)="
          checkExercises(requiredExercises, exercises.selected)
            ? save($event, exercises.selected)
            : handleDisplayModalError()
        "
        (cancelEmitter)="cancel($event)"
        [disabled]="exercises.selected.length == 0"
      ></confirm-buttons>
    </ng-container>
  </div>

  <ng-template #noEditable>
    <round-button
      set="uil"
      icon="edit"
      tooltip="ADMIN.EDIT"
      (click)="save(true)"
    ></round-button>
    <round-button
      [ngClass]="
        displayExercises && exercises.id == criteria?.name
          ? 'hsecondary'
          : 'hblack'
      "
      set="uil"
      icon="eye"
      tooltip="COURSES.COURSE.PREVIEW_EXERCISES"
      (click)="view(exercises.id)"
    ></round-button>
  </ng-template>
</form>
