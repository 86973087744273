import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { distinctUntilChanged, map } from "rxjs/operators";
import { Quiz } from "src/app/modules/quiz/interfaces/quiz";
import {
  QuizSolutionItem,
  QuizSolutionsListResults,
} from "src/app/modules/quiz/interfaces/quiz-solutions-list-results";
import { ItemListMode } from "src/app/shared/enums/item-list-mode";
import { Student } from "src/app/shared/interfaces/student";
import { HistoryListMode } from "src/app/shared/models/history-list-mode";
import * as fromQuizHistory from "src/app/store/actions/quiz-history.actions";
import * as courseSelectors from "src/app/store/selectors/course.selectors";
import { ModalComponent } from "../../modal/modal.component";

@Component({
  selector: "quiz-history-modal",
  templateUrl: "./quiz-history-modal.component.html",
  styleUrls: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuizHistoryModalComponent
  extends ModalComponent
  implements OnInit
{
  quizSolutionsList$: Observable<QuizSolutionsListResults> = this.store
    .select(courseSelectors.selectCourseState)
    .pipe(map((state) => state.quizSolutionsList as QuizSolutionsListResults));
  getQuizSolutionsListCompleted$: Observable<boolean> = this.store
    .select(courseSelectors.selectCourseState)
    .pipe(map((state) => state.getQuizSolutionsListCompleted));
  quizSolutionItem$: Observable<QuizSolutionItem> = this.store
    .select(courseSelectors.selectCourseState)
    .pipe(
      map((state) => state.quizSolutionItem),
      distinctUntilChanged((a, b) => a && b && a.id === b.id),
    );
  getQuizSolutionItemCompleted$: Observable<boolean> = this.store
    .select(courseSelectors.selectCourseState)
    .pipe(map((state) => state.getQuizSolutionItemCompleted));

  listMode: HistoryListMode;

  constructor(private store: Store<{}>) {
    super();
  }

  ngOnInit() {
    this.listMode = new HistoryListMode(
      this.data.multiMode ? ItemListMode.STUDENTS : ItemListMode.ITEM_LIST,
      this.data.isTeacher,
    );

    if (this.listMode.mode === ItemListMode.ITEM_LIST) {
      this.listMode.student = null;
      this.listMode.group = this.data.activeGroup;
      this.getStudentItems();
    }
  }

  getHistoryItem(item: QuizSolutionItem): void {
    this.store.dispatch(
      new fromQuizHistory.GetQuizSolution({
        id: item.id,
        CourseId: this.data.courseId,
        ExerciseId: this.data.exercise.id,
        GroupId: this.listMode.group,
        UserId: this.listMode.student ? this.listMode.student.id : undefined,
      }),
    );
  }

  nextPage(Page: number) {
    this.store.dispatch(
      new fromQuizHistory.GetQuizSolutionsListPage({
        CourseId: this.data.courseId,
        ExerciseId: this.data.exercise.id,
        GroupId: this.listMode.group,
        UserId: this.listMode.student ? this.listMode.student.id : undefined,
        Page,
      }),
    );
  }

  getStatusClass(
    list: QuizSolutionsListResults,
  ): (item: QuizSolutionItem) => string {
    const quiz: Quiz | null = list ? list.quiz : null;
    return (item) => {
      return item.score >=
        (item.quiz_pass_score
          ? item.quiz_pass_score
          : quiz
          ? quiz.pass_mark
          : 0)
        ? "accepted"
        : "rejected";
    };
  }

  getStatusIconClass(
    list: QuizSolutionsListResults,
  ): (item: QuizSolutionItem) => string {
    const quiz: Quiz | null = list ? list.quiz : null;
    return (item) => {
      return item.score >=
        (item.quiz_pass_score
          ? item.quiz_pass_score
          : quiz
          ? quiz.pass_mark
          : 0)
        ? "typcn-tick"
        : "typcn-times";
    };
  }

  getStudentItems(): void {
    this.store.dispatch(
      new fromQuizHistory.GetQuizSolutionsList({
        CourseId: this.data.courseId,
        ExerciseId: this.data.exercise.id,
        GroupId: this.listMode.group,
        UserId: this.listMode.student ? this.listMode.student.id : undefined,
      }),
    );
  }

  studentClicked(student: Student | null): void {
    this.listMode.student = student;
    this.listMode.group = this.data.activeGroup;
    this.getStudentItems();
    this.listMode.mode = ItemListMode.ITEM_LIST;
  }

  get titleSuffix(): string {
    return `${this.data.exercise.name}${
      this.listMode.student && this.listMode.mode === ItemListMode.ITEM_LIST
        ? ` - ${this.listMode.student.name}`
        : ``
    }`;
  }
}
