<div *ngIf="item" class="d-flex flex-column w-100">
  <div class="history-header">
    <h5 class="heading-1">{{ item.quiz.title }}</h5>
    <rich-text
      class="quiz-description"
      [content]="item.quiz.description"
    ></rich-text>

    <span class="label">
      {{ "COURSES.COURSE.EXERCISE.QUIZ_HISTORY.STARTED" | translate }}:
      {{ item.start | date }}
    </span>
    <span class="label" *ngIf="item.end">
      {{ "COURSES.COURSE.EXERCISE.QUIZ_HISTORY.ENDED" | translate }}:
      {{ item.end | date }}
    </span>
    <span class="label" *ngIf="item.quiz_pass_score">
      {{ "QUIZ.QUIZ_THRESHOLD_MODAL" | translate }}
      {{ item.quiz_pass_score }}
      {{ "GLOBAL.POINTS" | translate }}
    </span>

    <div class="mt-4">
      <badge
        theme="{{
          item.score >=
          (item.quiz_pass_score ? item.quiz_pass_score : item.quiz.pass_mark)
            ? 'hsecondary'
            : 'hinvalid'
        }}"
      >
        {{
          "COURSES.COURSE.EXERCISE.QUIZ_HISTORY." +
            (item.score >=
            (item.quiz_pass_score ? item.quiz_pass_score : list.quiz.pass_mark)
              ? "PASSED"
              : "FAILED") | translate
        }}
      </badge>
    </div>
  </div>

  <div
    class="quiz-question"
    *ngFor="let question of item.questions; let i = index"
  >
    <div class="d-flex flex-row justify-content-between">
      <div class="d-flex flex-row align-items-center">
        <h6 class="quiz-title pr-4">
          {{ "QUIZ.QUESTION" | translate }} {{ i + 1 }}
        </h6>
        <label class="quiz-caption">
          {{
            question.multi_choice
              ? ("QUIZ.EXERCISE.QUESTION_MULTIPLE_ANSWERS" | translate)
              : ("QUIZ.EXERCISE.QUESTION_SINGLE_ANSWER" | translate)
          }}
        </label>
      </div>
      <h6 class="quiz-title">
        {{ maxPoints }} {{ "GLOBAL.POINTS" | translate }}
      </h6>
    </div>

    <h6 class="quiz_desc pt-4">
      {{ question.content }}
    </h6>

    <rich-text class="explanation" [content]="question.explanation"></rich-text>

    <div
      class="mt-2 d-flex justify-content-center"
      *ngIf="question.figure.length"
    >
      <img
        class="figure-img"
        alt="{{ question.content }}"
        [src]="question.figure"
      />
    </div>
    <!-- <cached-quiz-figure
      [courseId]="courseId"
      [groupId]="isTeacher ? undefined : activeGroup"
      [exerciseId]="exerciseId"
      [question]="question"
    >
    </cached-quiz-figure> -->

    <div class="answers">
      <div
        *ngFor="let answer of question.answer_set"
        class="student-pass-condition-title"
      >
        <input
          type="checkbox"
          class="pass-course quiz-answer multi-choice no-bg"
          [checked]="list.quiz.show_selected_answers && answer.selected"
          [ngClass]="{
            correct: list.quiz.show_correct_answers && answer.correct,
            incorrect: list.quiz.show_correct_answers && !answer.correct
          }"
          disabled
        />
        <label class="pass-course--label checkbox">
          <span class="pass-course--label-head d-block">
            {{ answer.content | booleanString | translate }}
          </span>
        </label>
      </div>
    </div>
  </div>
</div>
