import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from "@angular/core";
import { StatsService } from "src/app/shared/services/statistics/stats.service";
import { ActivatedRoute, Params } from "@angular/router";
import { Guid } from "src/app/shared/types/guid";
import {
  CriteriaBarPayload,
  PartialGradesConfig,
} from "src/app/shared/interfaces/student-pass-criteria-api";
import { Store } from "@ngrx/store";
import { createPartialGradesLoad } from "src/app/store/actions/partial-grades/create-partial-grade.actions";
import { editPartialGradesLoad } from "src/app/store/actions/partial-grades/edit-partial-grade.actions";
import { deletePartialGradesLoad } from "src/app/store/actions/partial-grades/delete-partial-grade.actions";

@Component({
  selector: "student-partial-grades",
  templateUrl: "./student-partial-grades.component.html",
  styleUrls: ["./student-partial-grades.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StudentPartialGradesComponent implements OnInit {
  @Input() config: PartialGradesConfig;

  private CourseId: Guid;
  private GroupId: Guid;

  displayGradeCreator: boolean = false;
  currentGarlicId: string | null = null;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly stats: StatsService,
    private readonly store: Store,
  ) {}

  ngOnInit(): void {
    this.getStatsData();
  }

  getStatsData() {
    this.route.params.subscribe((params: Params) => {
      this.CourseId = params["CourseId"];
      this.GroupId = params["GroupId"];
    });
  }

  handleEditGarlic(GarlicId: string) {
    this.currentGarlicId = GarlicId;
  }

  handleSetGarlicCreator(value: boolean): void {
    if (value) {
      this.stats.changeSelectable(true);
      this.stats.clearExercises();
    }

    this.currentGarlicId = null;
    this.displayGradeCreator = value;
  }

  setSelectedExercises(exercises: string[]): void {
    if (exercises.length) this.handleSetGarlicCreator(false);
    else this.deletePartialGrade();
  }

  handleCreatePartialGrade(partialGrade: {
    form: CriteriaBarPayload;
    id: string;
  }): void {
    if (partialGrade.id)
      this.updatePartialGrade(partialGrade.form, partialGrade.id);
    else this.createPartialGrade(partialGrade.form);

    this.currentGarlicId = null;
  }

  private updatePartialGrade(
    partialGrade: CriteriaBarPayload,
    id: string,
  ): void {
    this.store.dispatch(
      editPartialGradesLoad({
        CourseId: this.CourseId,
        GroupId: this.GroupId,
        GarlicId: id,
        PartialGradePayload: partialGrade,
      }),
    );
  }

  private createPartialGrade(partialGrade: CriteriaBarPayload): void {
    this.store.dispatch(
      createPartialGradesLoad({
        CourseId: this.CourseId,
        GroupId: this.GroupId,
        PartialGradePayload: partialGrade,
      }),
    );
  }

  private deletePartialGrade(): void {
    const currentId = this.currentGarlicId;
    this.currentGarlicId = null;

    this.store.dispatch(
      deletePartialGradesLoad({
        CourseId: this.CourseId,
        GroupId: this.GroupId,
        GarlicId: currentId,
      }),
    );
  }
}
