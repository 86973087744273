import { createReducer, on } from "@ngrx/store";
import { HttpErrorResponse } from "@angular/common/http";
import {
  statsCriteriaLoad,
  statsCriteriaLoadFailure,
  statsCriteriaLoadSuccess,
} from "../../actions/stats/stats-criteria.actions";
import { statsCriteriaEditAllLoadSuccess } from "../../actions/stats/stats-crtieria-all-edit.actions";
import { CourseCriteria } from "src/app/shared/interfaces/student-pass-criteria-api";

export interface StatsCriteriaState {
  criteria: CourseCriteria | null;
  error: HttpErrorResponse | null;
}

const initialState: StatsCriteriaState = {
  criteria: null,
  error: null,
};

export const createStatsCriteriaReducer = createReducer(
  initialState,
  on(
    statsCriteriaLoad,
    (state): StatsCriteriaState => ({
      ...state,
      criteria: null,
      error: null,
    }),
  ),
  on(
    statsCriteriaLoadSuccess,
    (state, action): StatsCriteriaState => ({
      ...state,
      criteria: action.criteria,
      error: null,
    }),
  ),
  on(
    statsCriteriaEditAllLoadSuccess,
    (state, action): StatsCriteriaState => ({
      ...state,
      criteria: action.criteria,
      error: null,
    }),
  ),
  on(statsCriteriaLoadFailure, (state, { error }) => ({
    ...state,
    error: error,
  })),
);
