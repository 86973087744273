import {
  Component,
  DestroyRef,
  EventEmitter,
  Input,
  Output,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { combineLatest, Observable } from "rxjs";
import { filter, map, take } from "rxjs/operators";
import { Exercise } from "src/app/shared/interfaces/exercise";
import { Guid } from "src/app/shared/types/guid";
import * as fromUIStore from "src/app/store/reducers/ui.reducer";
import { QuizInfoStudentModalComponent } from "../../modals/quiz-info-student-modal/quiz-info-student-modal.component";
import { ModalService } from "src/app/shared/services/modal/modal.service";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import * as fromCourse from "src/app/store/actions/course.actions";
import * as fromCourseStore from "src/app/store/reducers/course.reducer";

@Component({
  selector: "div[exercise-quiz-control-buttons]",
  templateUrl: "./exercise-quiz-control-buttons.component.html",
  styleUrls: ["./exercise-quiz-control-buttons.component.scss"],
})
export class ExerciseQuizControlButtonsComponent {
  @Input() courseId: Guid;
  @Input() exercise: Exercise;
  @Input() activeGroup: Guid;
  @Input("teacher") isTeacher: boolean;
  @Output() onStart: EventEmitter<MouseEvent> = new EventEmitter();

  isRunning$: Observable<boolean> = this.store
    .select((state) => state.ui.runningExercise)
    .pipe(
      map((runningExercise) =>
        runningExercise[0]
          ? runningExercise[0].id === this.exercise.id &&
            (this.isTeacher || runningExercise[0].group_id === this.activeGroup)
          : false,
      ),
    );

  exerciseProperites$ = this.store.select(
    (store) => store.student?.singleCourse?.exercise,
  );

  exerciseProperitesStatus$ = this.store.select(
    (store) => store.student?.singleCourse?.requestExerciseStatus,
  );

  constructor(
    private store: Store<{
      ui: fromUIStore.UIState;
      student: {
        singleCourse: fromCourseStore.CourseState;
      };
    }>,
    private modal: ModalService,
    private router: Router,
    private route: ActivatedRoute,
    private destroyRef: DestroyRef,
  ) {}

  start(event: MouseEvent, isRunning: boolean): void {
    if (this.isTeacher) {
      this.handleTeacherStart(event, isRunning);
    } else {
      this.handleStudentStart(event, isRunning);
    }
  }

  private handleTeacherStart(event: MouseEvent, isRunning: boolean): void {
    if (isRunning) {
      this.navigateToQuiz();
    } else {
      if (this.activeGroup) {
        this.updateTeacherUrlWithGroup();
      }
      this.onStart.emit(event);
    }
  }

  private handleStudentStart(event: MouseEvent, isRunning: boolean): void {
    this.store.dispatch(
      new fromCourse.GetExercise({
        CourseId: this.courseId,
        GroupId: this.activeGroup,
        ExerciseId: this.exercise.id,
        TopicId: "",
      }),
    );

    combineLatest([this.exerciseProperites$, this.exerciseProperitesStatus$])
      .pipe(
        filter(([_, status]) => status === "SUCCESS"),
        take(1),
      )
      .subscribe(([exercise]) => {
        const modal = this.modal.showModal(
          {
            timeLimit: exercise?.quiz_properties?.time_limit,
            maxAttempts: exercise?.quiz_properties?.max_attempts,
          },
          QuizInfoStudentModalComponent,
          "QUIZ_INFORMATION_FOR_STUDENT",
        ) as QuizInfoStudentModalComponent;

        modal.close
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe((res) => {
            this.store.dispatch(new fromCourse.GetExerciseRestart());

            if (res === "confirm") {
              if (isRunning) {
                this.navigateToQuiz();
              } else {
                if (this.activeGroup) {
                  this.updateTeacherUrlWithGroup();
                }
                this.onStart.emit(event);
              }
            }
          });
      });
  }

  private navigateToQuiz(): void {
    const basePath = `/quiz/${this.courseId}`;
    const groupPath = this.isTeacher ? "/" : `/groups/${this.activeGroup}/`;
    const fullPath = `${basePath}${groupPath}exercise/${this.exercise.id}`;

    const queryParams =
      this.isTeacher && this.activeGroup
        ? { returnGroupId: this.activeGroup }
        : {};

    this.router.navigate([fullPath], { queryParams });
  }

  private updateTeacherUrlWithGroup(): void {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { returnGroupId: this.activeGroup },
      queryParamsHandling: "merge",
      replaceUrl: true,
    });
  }
}
