import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import {
  CriteriaBarPayload,
  GarlicConfig,
  PartialGradesConfig,
} from "../../interfaces/student-pass-criteria-api";
import { Guid } from "../../types/guid";
import { ApiService } from "../api/api.service";

@Injectable({
  providedIn: "root",
})
export class PartialGradesService {
  constructor(private apiService: ApiService) {}

  enablePartialGrades(
    courseId: Guid,
    groupId: Guid,
    config: any,
  ): Observable<GarlicConfig> {
    return this.apiService.postRequest(
      `courses/${courseId}/groups/${groupId}/grades/create_grades/`,
      { grades: config },
    );
  }

  destroyPartialGrades(courseId: Guid, groupId: Guid): Observable<any> {
    return this.apiService.deleteRequest(
      `courses/${courseId}/groups/${groupId}/grades/destroy_grades`,
    );
  }

  getPartialGrades(
    courseId: Guid,
    groupId: Guid,
  ): Observable<PartialGradesConfig> {
    return this.apiService.getRequest(
      `courses/${courseId}/groups/${groupId}/grades/`,
    );
  }

  getStudentPartialGrades(
    courseId: Guid,
    groupId: Guid,
  ): Observable<PartialGradesConfig> {
    return this.apiService.getRequest(
      `courses/${courseId}/groups/${groupId}/my-grades/`,
    );
  }

  createPartialGrade(
    courseId: Guid,
    groupId: Guid,
    partialGrade: CriteriaBarPayload,
  ): Observable<any> {
    return this.apiService.postRequest(
      `courses/${courseId}/groups/${groupId}/grades/`,
      partialGrade,
    );
  }

  editPartialGrade(
    courseId: Guid,
    groupId: Guid,
    garlicId: Guid,
    partialGrade: CriteriaBarPayload,
  ): Observable<any> {
    return this.apiService.putRequest(
      `courses/${courseId}/groups/${groupId}/grades/${garlicId}/`,
      partialGrade,
    );
  }

  deletePartialGrade(
    courseId: Guid,
    groupId: Guid,
    garlicId: string,
  ): Observable<void> {
    return this.apiService.deleteRequest(
      `courses/${courseId}/groups/${groupId}/grades/${garlicId}/`,
    );
  }

  calculatePartialGrades(courseId: Guid, groupId: Guid): Observable<any> {
    return this.apiService.postRequest(
      `courses/${courseId}/groups/${groupId}/grades/update_grades/`,
      {},
    );
  }
}
