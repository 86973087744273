import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from "@angular/core";

@Component({
  selector: "course-exercise-answer-menu",
  templateUrl: "./course-exercise-answer-menu.component.html",
  styleUrls: ["./course-exercise-answer-menu.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CourseExerciseAnswerMenuComponent {
  @Output() showModalEvent = new EventEmitter<void>();

  @Input() count: number = 0;
  @Input() studentsList: number = 0;

  get isModalButtonDisabled(): boolean {
    return this.studentsList === 0;
  }

  handleShowAnswerListModal(): void {
    this.showModalEvent.emit();
  }
}
