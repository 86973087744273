import { createReducer, on } from "@ngrx/store";
import { HttpErrorResponse } from "@angular/common/http";
import {
  getPartialGradesLoad,
  getPartialGradesLoadFailure,
  getPartialGradesLoadSuccess,
} from "../../actions/partial-grades/get-partial-grades.actions";
import { enablePartialGradesLoadSuccess } from "../../actions/partial-grades/enable-partial-grades.actions";
import { PartialGradesConfig } from "src/app/shared/interfaces/student-pass-criteria-api";

export interface GetPartialGradesState {
  config: PartialGradesConfig;
  error: HttpErrorResponse | null;
}

const initialState: GetPartialGradesState = {
  config: null,
  error: null,
};

export const getPartialGradesReducer = createReducer(
  initialState,
  on(
    getPartialGradesLoad,
    (state): GetPartialGradesState => ({
      ...state,
      config: null,
      error: null,
    }),
  ),
  on(
    getPartialGradesLoadSuccess,
    (state, action): GetPartialGradesState => ({
      ...state,
      config: action.config,
      error: null,
    }),
  ),
  on(
    enablePartialGradesLoadSuccess,
    (state, action): GetPartialGradesState => ({
      ...state,
      config: {
        ...state.config,
        id: action.config.id,
        grades: action.config.grades,
      },
      error: null,
    }),
  ),
  on(getPartialGradesLoadFailure, (state, { error }) => ({
    ...state,
    error: error,
  })),
);
