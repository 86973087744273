import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  inject,
} from "@angular/core";
import { Observable } from "rxjs";
import { StatsService } from "../../services/statistics/stats.service";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { tap } from "rxjs/operators";
import {
  Config,
  CriteriaBarPayload,
} from "../../interfaces/student-pass-criteria-api";
import { ConfirmationModalComponent } from "../modals/confirmation-modal/confirmation-modal.component";
import { ModalService } from "../../services/modal/modal.service";

@Component({
  selector: "partial-grade-bar",
  templateUrl: "./partial-grade-bar.component.html",
  styleUrls: ["./partial-grade-bar.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PartialGradeBarComponent implements OnInit, OnChanges {
  @Input() config: Config | null;
  @Input() creatorMode: boolean = false;
  @Input() isEditing: boolean = false;
  @Input() studentView: boolean = false;

  @Output() acceptEmitter = new EventEmitter<{
    form: CriteriaBarPayload;
    id: string | null;
  }>();
  @Output() cancelEmitter = new EventEmitter<string[]>();
  @Output() editEmitter = new EventEmitter<string>();

  private stats = inject(StatsService);
  private fb = inject(FormBuilder);
  private modal = inject(ModalService);

  currentPartialGradeId$: Observable<string> = this.stats.currentPartialGradeId;
  selectedExercises$: Observable<string[]> = this.stats.currentItems;
  selectable$: Observable<boolean> = this.stats.selectableFlag;

  displayExercises: boolean = false;
  loader: boolean = false;
  form = this.initForm();

  get is_active() {
    return this.form.controls.is_active as FormControl<boolean>;
  }

  get name() {
    return this.form.controls.name as FormControl<string>;
  }

  get exercises() {
    return this.form.controls.exercises as FormControl<string[]>;
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { isEditing, config } = changes;

    if (isEditing?.currentValue) {
      if (this.isEditing) {
        this.form.enable();
        this.is_active.disable();
      } else {
        this.form.disable();
        this.is_active.enable();
      }
    }

    if (config?.currentValue) {
      this.loader = false;
      this.stats.changeSelectable(false);
    }
  }

  ngOnInit(): void {
    if (this.config) {
      this.loadData();
    }

    this.setUpValidators();
  }

  private initForm(): FormGroup {
    return this.fb.group({
      is_active: this.fb.control<boolean>(true),
      name: this.fb.control<string>("", [Validators.required]),
      exercises: this.fb.control<string[]>([]),
    });
  }

  private setUpValidators(): void {
    if (this.creatorMode) {
      this.exercises.setValidators([Validators.required]);
    } else {
      this.exercises.clearValidators();
    }
    this.exercises.updateValueAndValidity();
  }

  private loadData(): void {
    const { name, is_active, exercises } = this.config;

    this.form.patchValue({
      name,
      is_active,
      exercises,
    });
  }

  acceptGrade(): void {
    this.selectedExercises$
      .pipe(
        tap((exercises) => {
          this.form.patchValue({ exercises });
        }),
      )
      .subscribe();

    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    if (!this.creatorMode && this.config && this.exercises.value.length === 0) {
      const modal = this.modal.showModal(
        {
          modalTitle: "COURSES.COURSE.DELETE_GRADE_TITLE",
          message: this.config.name,
          confirmButtonText: "GROUPS.EDIT.DELETE_BUTTON",
          cancelButtonText: "GLOBAL.CANCEL_BUTTON",
          confirmButtonColor: "primary",
        },
        ConfirmationModalComponent,
        "REMOVE_PARTIAL_GRADE",
      ) as ConfirmationModalComponent;

      modal.onConfirm
        .pipe(
          tap((confirm) => {
            if (confirm) {
              this.cancelEmitter.emit(this.exercises.value);
            }
          }),
        )
        .subscribe();
    } else {
      this.loader = true;
      this.isEditing = false;

      this.stats.setCurrentPartialGrade(null);
      this.stats.changeSelectable(false);

      this.acceptEmitter.emit({
        form: {
          name: this.name.value,
          exercises: this.exercises.value,
          is_active: true,
        },
        id: this.config ? this.config.id : null,
      });
    }
  }

  cancelGrade(): void {
    if (!this.creatorMode) {
      const { name, exercises, is_active } = this.config;
      this.name.setValue(name);
      this.is_active.setValue(is_active);
      this.cancelEmitter.emit(exercises);
      this.is_active.enable();
    } else {
      this.cancelEmitter.emit([]);
    }

    this.stats.changeSelectable(false);
  }

  handleDisplayPartialGrade(value: boolean): void {
    this.acceptEmitter.emit({
      form: {
        name: this.name.value,
        exercises: this.exercises.value,
        is_active: value,
      },
      id: this.config ? this.config.id : null,
    });
  }

  editGrade(): void {
    this.stats.setCurrentPartialGrade(this.config.id);
    this.stats.changeSelectable(true);
    this.stats.changeHighlightable(false);
    this.stats.clearExercises();

    this.isEditing = true;
    this.config.exercises.map((exercise) => this.stats.addItem(exercise));

    this.editEmitter.emit(this.config.id);
  }

  viewExercises(identifier: string, isStudentView: boolean): void {
    const currentIdentifier = isStudentView ? this.config.name : this.config.id;
    const isSame = currentIdentifier === identifier;

    this.displayExercises = !isSame;
    this.stats.setCurrentPartialGrade(isSame ? null : currentIdentifier);
    this.stats.changeHighlightable(this.displayExercises);

    this.stats.clearExercises();
    if (this.displayExercises) {
      this.config.exercises.forEach((exercise) => this.stats.addItem(exercise));
    }
  }
}
