import { HttpErrorResponse } from "@angular/common/http";
import { createAction, props } from "@ngrx/store";
import {
  Config,
  CriteriaBarPayload,
} from "src/app/shared/interfaces/student-pass-criteria-api";

export const prefix = "[Create Partial Grades]";

const createPartialGradesActions = {
  request: `${prefix} Load`,
  requestActiveSuccess: `${prefix} Load success`,
  requestFail: `${prefix} Load fail`,
};

export const createPartialGradesLoad = createAction(
  createPartialGradesActions.request,
  props<{
    CourseId: string;
    GroupId: string;
    PartialGradePayload: CriteriaBarPayload;
  }>(),
);

export const createPartialGradesLoadSuccess = createAction(
  createPartialGradesActions.requestActiveSuccess,
  props<{ config: Config }>(),
);

export const createPartialGradesLoadFailure = createAction(
  createPartialGradesActions.requestFail,
  props<{ error: HttpErrorResponse }>(),
);
