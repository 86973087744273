import { Injectable } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { act, Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { EMPTY, of, race } from "rxjs";
import {
  catchError,
  map,
  mergeMap,
  retryWhen,
  switchMap,
  tap,
  withLatestFrom,
} from "rxjs/operators";
import * as errorConfig from "src/app/config/errors";
import { ExerciseSolveConfirmModalComponent } from "src/app/shared/components/modals/exercise-solve-confirm-modal/exercise-solve-confirm-modal.component";
import { VpnConnectionModalComponent } from "src/app/shared/components/modals/vpn-connection-modal/vpn-connection-modal.component";
import { Course } from "src/app/shared/interfaces/course";
import { CourseGroup } from "src/app/shared/interfaces/course-group";
import { Exercise } from "src/app/shared/interfaces/exercise";
import { Hint } from "src/app/shared/interfaces/hint";
import { Member } from "src/app/shared/interfaces/member";
import { VideoHint } from "src/app/shared/interfaces/video-hint";
import { WrongAnswerPayload } from "src/app/shared/interfaces/wrong-answer-payload";
import { CourseService } from "src/app/shared/services/course/course.service";
import { FileService } from "src/app/shared/services/file/file.service";
import { ModalService } from "src/app/shared/services/modal/modal.service";
import { WarningModalService } from "src/app/shared/services/warning-modal/warning-modal.service";
import { AnswerType } from "src/app/shared/types/answer-type";
import * as fromAccountStore from "src/app/store/reducers/account.reducer";
import { environment } from "src/environments/environment";
import * as fromCourse from "../actions/course.actions";
import * as fromUI from "../actions/ui.actions";
import { ExerciseControl } from "src/app/shared/interfaces/exercise-control";
import { HttpErrorResponse } from "@angular/common/http";
import { ConfirmationModalComponent } from "src/app/shared/components/modals/confirmation-modal/confirmation-modal.component";
import { RunningExerciseData } from "src/app/shared/types/running-exercise";
import { Guid } from "src/app/shared/types/guid";
import { StatElement } from "src/app/shared/interfaces/student";
import { PdfReportService } from "../../shared/services/pdfreport/pdfreport.service";
import {
  courseProgressLoad,
  courseProgressLoadSuccess,
  loadCourseProgressFailure,
} from "../actions/course-progress.actions";
import { CourseProgress } from "src/app/shared/interfaces/course-progress";
import { PdfReport } from "src/app/shared/interfaces/pdfreport";

@Injectable()
export class CourseEffect {
  getCourse$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromCourse.GET_COURSE),
      mergeMap((action: fromCourse.GetCourse) =>
        this.courseService.getCourse(action.payload).pipe(
          mergeMap((course: Course) => {
            const activeTopic = this.courseService.determineActiveTopic(
              course.topics,
              false,
            );

            return [
              {
                type: fromCourse.SET_ACTIVE_GROUP,
                payload: null,
              },
              {
                type: fromCourse.GET_COURSE_COMPLETED,
                payload: course,
              },
              {
                type: fromCourse.GET_TOPIC_STATISTICS,
                payload: {
                  CourseId: action.payload.CourseId,
                },
              },
              {
                type: fromCourse.SET_CURRENT_TOPIC,
                payload: {
                  Topic: activeTopic,
                  CourseId: action.payload.CourseId,
                  GroupId: null,
                },
              },
            ];
          }),
          catchError(() =>
            of({
              type: fromCourse.GET_COURSE_FAILED,
            }),
          ),
        ),
      ),
    ),
  );

  getCourseByGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromCourse.GET_COURSE_BY_GROUP),
      mergeMap((action: fromCourse.GetCourseByGroup) =>
        this.courseService.getCourseByGroup(action.payload).pipe(
          mergeMap((course: CourseGroup) => {
            let actionsToDispatch: Array<{ type: string; payload: any }> = [
              {
                type: fromCourse.SET_ACTIVE_GROUP,
                payload: action.payload.GroupId,
              },
              {
                type: fromCourse.GET_COURSE_COMPLETED,
                payload: course,
              },
            ];

            if (!action.pure) {
              const activeTopic = this.courseService.determineActiveTopic(
                course.topics,
              );

              if (activeTopic) {
                actionsToDispatch.push({
                  type: fromCourse.SET_CURRENT_TOPIC,
                  payload: {
                    Topic: activeTopic,
                    CourseId: action.payload.CourseId,
                    GroupId: action.payload.GroupId,
                  },
                });
              }
            }

            return actionsToDispatch;
          }),

          catchError((error) => {
            return of({
              type: fromCourse.GET_COURSE_FAILED,
            });
          }),
        ),
      ),
    ),
  );

  setCurrentTopic$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromCourse.SET_CURRENT_TOPIC),
      mergeMap((action: fromCourse.SetCurrentTopic) => {
        if (action.payload.GroupId === null) {
          return EMPTY;
        } else {
          return [
            {
              type: fromCourse.GET_TOPIC_STATISTICS,
              payload: {
                CourseId: action.payload.CourseId,
                GroupId: action.payload.GroupId,
                TopicId: action.payload.Topic.id,
              },
            },
          ];
        }
      }),
    ),
  );

  getCourseStatistics$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromCourse.GET_COURSE_STATISTICS),
      mergeMap((action: fromCourse.GetCourseStatistics) => {
        return this.courseService.getCourseStatistics(action.payload).pipe(
          map((stats) => {
            if (!stats.hasOwnProperty("id")) {
              const statsWithUser = Object.keys(stats).map(
                (studentId: Guid) => ({
                  id: studentId,
                  ...stats[studentId],
                  topics: Object.keys(stats[studentId].topics).map(
                    (topicId: Guid) => {
                      return {
                        id: topicId,
                        ...stats[studentId].topics[topicId],
                        exercises: Object.keys(
                          stats[studentId].topics[topicId].exercises,
                        ).map((exerciseId: Guid) => {
                          return {
                            id: exerciseId,
                            ...stats[studentId].topics[topicId].exercises[
                              exerciseId
                            ],
                          };
                        }),
                      };
                    },
                  ),
                }),
              );

              return {
                stats_with_user: statsWithUser,
                stats_without_user: null,
              };
            } else {
              return { stats_with_user: null, stats_without_user: stats };
            }
          }),
          map((stats: StatElement) => {
            return {
              type: fromCourse.GET_COURSE_STATISTICS_COMPLETED,
              payload: stats,
            };
          }),
        );
      }),
    ),
  );

  getTopicStatistics$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromCourse.GET_TOPIC_STATISTICS),
      mergeMap((action: fromCourse.GetTopicStatistics) => {
        if (
          this.router.url.includes("teacher/courses") &&
          typeof action.payload.CourseId !== "undefined" &&
          typeof action.payload.GroupId !== "undefined"
        ) {
          return this.courseService.getTopicStatistics(action.payload).pipe(
            map((stats) => {
              return {
                type: fromCourse.GET_TOPIC_STATISTICS_COMPLETED,
                payload: stats,
              };
            }),
          );
        } else {
          return EMPTY;
        }
      }),
    ),
  );

  exportCourseStatistics$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromCourse.EXPORT_COURSE_STATISTICS),
      switchMap((action: fromCourse.ExportCourseStatistics) =>
        this.courseService.exportCourseStatistics(action.payload).pipe(
          switchMap((statsFile) => {
            let courseFileName = this.fileService.parseName(
              action.payload.CourseName,
            );
            let groupFileName = this.fileService.parseName(
              action.payload.GroupName,
            );

            this.fileService.download(
              statsFile,
              `${courseFileName}-${groupFileName}-stats.xlsx`,
            );

            return [new fromCourse.ExportCourseStatisticsCompleted()];
          }),
          catchError((error) =>
            of(new fromCourse.ExportCourseStatisticsFailed(error)),
          ),
        ),
      ),
    ),
  );

  downloadIndividualReport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromCourse.DOWNLOAD_INDIVIDUAL_REPORT),
      switchMap((action: fromCourse.DownloadIndividualReport) => {
        return this.pdfreportService
          .downloadIndividualReport(action.payload)
          .pipe(
            tap((res: PdfReport) => {
              this.fileService.downloadS3File(
                res.s3_url,
                action.payload.UserName,
              );
            }),
            map(() => ({
              type: fromCourse.DOWNLOAD_INDIVIDUAL_REPORT_SUCCESS,
            })),
            catchError(() =>
              of({ type: fromCourse.DOWNLOAD_INDIVIDUAL_REPORT_FAILED }),
            ),
          );
      }),
    ),
  );

  downloadGroupReport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromCourse.DOWNLOAD_GROUP_REPORT),
      switchMap((action: fromCourse.DownloadGroupReport) => {
        return this.pdfreportService.downloadGroupReport(action.payload).pipe(
          tap((res: PdfReport) => {
            this.fileService.downloadS3File(
              res.s3_url,
              action.payload.GroupName,
            );
          }),
          map(() => ({ type: fromCourse.DOWNLOAD_GROUP_REPORT_SUCCESS })),
          catchError(() =>
            of({ type: fromCourse.DOWNLOAD_GROUP_REPORT_FAILED }),
          ),
        );
      }),
    ),
  );

  getHelp$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromCourse.GET_HELP),
      mergeMap((action: fromCourse.GetHelp) =>
        this.courseService.getHelp(action.payload).pipe(
          map((hints: Array<Hint>) => {
            return {
              type: fromCourse.GET_HELP_COMPLETED,
              payload: {
                hints,
                TopicId: action.payload.TopicId,
                ExerciseId: action.payload.ExerciseId,
              },
            };
          }),
        ),
      ),
    ),
  );

  getVideoHelp$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromCourse.GET_VIDEO_HELP),
      mergeMap((action: fromCourse.GetVideoHelp) =>
        this.courseService.getVideoHelp(action.payload).pipe(
          map((video: Array<VideoHint>) => {
            return {
              type: fromCourse.GET_VIDEO_HELP_COMPLETED,
              payload: {
                video,
                TopicId: action.payload.TopicId,
                ExerciseId: action.payload.ExerciseId,
              },
            };
          }),
        ),
      ),
    ),
  );

  startExercise$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromCourse.START_EXERCISE, fromCourse.START_TEACHER_EXERCISE),
      withLatestFrom(this.store.select((state) => state.account.member)),
      mergeMap(
        ([action, member]: [
          fromCourse.StartExercise | fromCourse.StartTeacherExercise,
          Member,
        ]) => {
          const exerciseId = action.payload.ExerciseId;
          const startMethod =
            action.type === fromCourse.START_EXERCISE
              ? this.courseService.startExercise(action.payload)
              : this.courseService.startTeacherExercise(action.payload);

          if (
            environment.production &&
            action.payload.requires_vpn &&
            member &&
            !member.remote_ip
          ) {
            this.modalService.showModal(
              {},
              VpnConnectionModalComponent,
              "VPN_CONNECTION_REMIND",
            );
          }

          return startMethod.pipe(
            mergeMap((exercise: Exercise) => {
              let actions: Array<{ type: string; payload?: any }> = [
                {
                  type: fromCourse.START_EXERCISE_COMPLETED,
                  payload: { exercise, TopicId: action.payload.TopicId },
                },
              ];

              if (
                [
                  AnswerType.ANSWER_TYPE_CODE,
                  AnswerType.ANSWER_TYPE_QUIZ,
                  AnswerType.ANSWER_TYPE_CONTAINER,
                  AnswerType.ANSWER_TYPE_UPLOAD,
                ].includes(action.payload.answer_type)
              ) {
                actions.push({ type: fromUI.GET_RUNNING_EXERCISE });
              }

              return actions;
            }),
            retryWhen((errors) =>
              errors.pipe(
                switchMap((errorResponse: HttpErrorResponse) => {
                  if (
                    errorResponse.status === 400 &&
                    errorResponse.error.started &&
                    errorResponse.error.started.type ===
                      "EXERCISE_ALREADY_RUNNING"
                  ) {
                    const running_exercise: RunningExerciseData =
                      errorResponse.error.started.running_exercise;

                    const stopPayload: ExerciseControl = {
                      CourseId: running_exercise.course_id,
                      GroupId: running_exercise.group_id,
                      TopicId: running_exercise.topic_id,
                      ExerciseId: running_exercise.id,
                    };

                    const stopMethod =
                      action.type === fromCourse.START_EXERCISE
                        ? this.courseService.stopExercise(stopPayload)
                        : this.courseService.stopTeacherExercise(stopPayload);

                    const modal = this.modalService.showModal(
                      {
                        modalTitle: "GLOBAL.MESSAGES.INFO",
                        message: "COURSES.COURSE.EXERCISE.ALREADY_RUNNING",
                        confirmButtonText: "GLOBAL.YES",
                        cancelButtonText: "GLOBAL.NO",
                        translationParams: { title: running_exercise.name },
                      },
                      ConfirmationModalComponent,
                      "CONFIRMATION",
                    ) as ConfirmationModalComponent;

                    return race([
                      modal.onConfirm.pipe(map(() => "confirm")),
                      modal.onCancel.pipe(map(() => "cancel")),
                    ]).pipe(
                      switchMap((decision) => {
                        if (decision === "confirm") {
                          return stopMethod.pipe(
                            map((response) => {
                              if (response.status === "not-started") {
                                this.store.dispatch(
                                  new fromUI.SetStartedId(exerciseId),
                                );

                                return true;
                              } else {
                                throw "cant-stop";
                              }
                            }),
                          );
                        } else {
                          throw errorResponse;
                        }
                      }),
                    );
                  }
                  throw errorResponse;
                }),
              ),
            ),
            catchError((errorResponse: HttpErrorResponse) => {
              if (errorResponse.error.started) {
                this.warningModalService.showModal({
                  modalTitle: errorResponse.error.started.detail
                    ? `GLOBAL.MESSAGES.ERROR_TITLES.ERROR_${errorResponse.status}`
                    : ``,
                  text: errorResponse.error.started.detail
                    ? errorResponse.error.started.detail
                    : errorResponse.error.started[0],
                  icon: errorConfig.ERROR_ICONS[errorResponse.status],
                });
              }

              return of({
                type: fromCourse.START_EXERCISE_FAILED,
                payload: action.payload,
              });
            }),
          );
        },
      ),
    ),
  );

  stopExercise$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromCourse.STOP_EXERCISE, fromCourse.STOP_TEACHER_EXERCISE),
      mergeMap(
        (action: fromCourse.StopExercise | fromCourse.StopTeacherExercise) => {
          const stopMethod =
            action.type === fromCourse.STOP_EXERCISE
              ? this.courseService.stopExercise(action.payload)
              : this.courseService.stopTeacherExercise(action.payload);

          return stopMethod.pipe(
            mergeMap((exercise: Exercise) => [
              {
                type: fromCourse.STOP_EXERCISE_COMPLETED,
                payload: { exercise, TopicId: action.payload.TopicId },
              },
              {
                type: fromUI.RESET_RUNNING_EXERCISE,
              },
            ]),
            catchError(() => {
              return of({
                type: fromCourse.STOP_EXERCISE_FAILED,
              });
            }),
          );
        },
      ),
    ),
  );

  getExercise$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromCourse.GET_EXERCISE),
      mergeMap((action: fromCourse.GetExercise) =>
        this.courseService.getExercise(action.payload).pipe(
          mergeMap((exercise: Exercise) => {
            return [
              {
                type: fromCourse.GET_EXERCISE_COMPLETED,
                payload: exercise,
              },
            ];
          }),
          catchError(() =>
            of({
              type: fromCourse.GET_EXERCISE_FAILED,
            }),
          ),
        ),
      ),
    ),
  );

  selectUnselectExercise$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromCourse.SELECT_UNSELECT_EXERCISE),
      mergeMap((action: fromCourse.SelectUnselectExercise) =>
        this.courseService.selectUnselect(action.payload).pipe(
          map((exercise: Exercise) => {
            return {
              type: fromCourse.SELECT_UNSELECT_EXERCISE_COMPLETED,
              payload: { exercise, TopicId: action.payload.selection.TopicId },
            };
          }),
          tap(() => {
            if (action.payload.displayModal) {
              this.modalService.showModal(
                {
                  modalTitle: "QUIZ.SETTINGS.SAVE_TITLE",
                  message: "QUIZ.SETTINGS.SAVE_DESC",
                  confirmButtonText: "GLOBAL.NEXT",
                  confirmButtonColor: "primary",
                  cancelButtonHide: true,
                  icon: "success",
                },
                ConfirmationModalComponent,
                "QUIZ_SETTINGS_CONFIRMATION",
              );
            }
          }),
          catchError((error: HttpErrorResponse) => {
            switch (error.status) {
              case 400:
                this.warningModalService.showModal({
                  modalTitle: `QUIZ.ERROR.ERROR_TITLE`,
                  text: "QUIZ.ERROR.ERROR_SUBTITLE",
                  icon: "cancel-outline",
                });
                break;
            }

            return EMPTY;
          }),
        ),
      ),
    ),
  );

  selectUnselectAll$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromCourse.SELECT_UNSELECT_ALL),
      mergeMap((action: fromCourse.SelectUnselectAll) =>
        this.courseService.selectUnselectAll(action.payload).pipe(
          map((response: Array<Exercise>) => {
            return {
              type: fromCourse.SELECT_UNSELECT_ALL_COMPLETED,
              payload: {
                TopicId: action.payload.selection.TopicId,
                exercises: response,
                selectionParamName: action.payload.selectionParamName,
              },
            };
          }),
        ),
      ),
    ),
  );

  answer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromCourse.ANSWER),
      mergeMap((action: fromCourse.Answer) =>
        this.courseService.answer(action.payload).pipe(
          mergeMap((result: Exercise | WrongAnswerPayload) => {
            if (result.hasOwnProperty("detail") && result.passed === false) {
              this.translate
                .get("GLOBAL.MESSAGES.ERROR_TEXT", {
                  message: (result as WrongAnswerPayload).detail,
                })
                .subscribe((resource) => {
                  this.warningModalService.showModal({
                    modalTitle: `GLOBAL.MESSAGES.ERROR_TITLES.ERROR_200`,
                    text: resource,
                    icon: errorConfig.ERROR_ICONS[200],
                  });
                });

              return [
                {
                  type: fromCourse.ANSWER_COMPLETED_WRONG,
                  payload: {
                    exercise: {
                      id: action.payload.ExerciseId,
                    },
                  },
                },
              ];
            } else {
              const actions = [
                {
                  type: fromCourse.ANSWER_COMPLETED,
                  payload: {
                    exercise: result,
                    TopicId: action.payload.TopicId,
                  },
                },
                {
                  type: fromUI.RESET_RUNNING_EXERCISE,
                },
              ];

              if (action.payload.CourseId && action.payload.GroupId) {
                actions.push(
                  courseProgressLoad({
                    CourseId: action.payload.CourseId,
                    GroupId: action.payload.GroupId,
                  }),
                );
              }

              this.modalService.showModal(
                result,
                ExerciseSolveConfirmModalComponent,
                "EXERCISE_SOLVE_CONFIRM_MODAL",
              );

              return actions;
            }
          }),
          catchError((error) =>
            of({
              type: fromCourse.ANSWER_COMPLETED_WRONG,
              payload: {
                exercise: {
                  id: action.payload.ExerciseId,
                },
              },
            }),
          ),
        ),
      ),
    ),
  );

  getCourseProgress$ = createEffect(() =>
    this.actions$.pipe(
      ofType(courseProgressLoad),
      switchMap((param) => {
        return this.courseService
          .getCourseProgress(param.CourseId, param.GroupId)
          .pipe(
            map((res: CourseProgress) =>
              courseProgressLoadSuccess({ courseProgress: res }),
            ),
            catchError(({ error }) => of(loadCourseProgressFailure(error))),
          );
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private courseService: CourseService,
    private pdfreportService: PdfReportService,
    private router: Router,
    private translate: TranslateService,
    private warningModalService: WarningModalService,
    private modalService: ModalService,
    private fileService: FileService,
    private route: ActivatedRoute,
    private store: Store<{ account: fromAccountStore.AccountState }>,
  ) {}
}
