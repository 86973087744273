import { Injectable } from "@angular/core";
import { Observable } from "rxjs/internal/Observable";
import { AnswerPayload } from "../../interfaces/answer-payload";
import { Course } from "../../interfaces/course";
import { CourseGroup } from "../../interfaces/course-group";
import { Exercise } from "../../interfaces/exercise";
import { ExerciseControl } from "../../interfaces/exercise-control";
import {
  ExerciseSelection,
  ExerciseSelectionParams,
} from "../../interfaces/exercise-selection";
import { HelpRequest } from "../../interfaces/help-request";
import { Hint } from "../../interfaces/hint";
import { Guid } from "../../types/guid";
import { ApiService } from "../api/api.service";
import { VideoHint } from "../../interfaces/video-hint";
import { Topic } from "../../interfaces/topic";
import { ActivatedRoute, Router } from "@angular/router";
import { TopicSelection } from "../../interfaces/topic-selection";
import {
  ImmutableExercise,
  ImmutableExerciseMap,
} from "../../types/immutable-exercise";
import { fromJS, OrderedMap } from "immutable";
import {
  StudentStatElementDetails,
  StudentStatElement,
} from "../../interfaces/student";
import { StudentStatResponse } from "../../interfaces/student-stat-response";
import { HttpContextBuilder } from "../../http/context/builders/http-context-builder.service";
import { CourseProgress } from "../../interfaces/course-progress";

@Injectable({
  providedIn: "root",
})
export class CourseService {
  constructor(
    private readonly apiService: ApiService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly contextBuilder: HttpContextBuilder,
  ) {}

  getCourse(data: { CourseId: Guid }): Observable<Course> {
    const context = this.contextBuilder.skipBy404Token().build();

    return this.apiService.getRequest<Course>(`courses/${data.CourseId}/`, {
      context: context,
    });
  }

  getCourseByGroup(data: {
    CourseId: Guid;
    GroupId: Guid;
  }): Observable<CourseGroup> {
    const context = this.contextBuilder.skipBy404Token().build();

    return this.apiService.getRequest<CourseGroup>(
      `courses/${data.CourseId}/groups/${data.GroupId}/`,
      { context },
    );
  }

  getCourseStatistics(data: { CourseId: Guid; GroupId: Guid }) {
    const context = this.contextBuilder.skipBy404Token().build();

    return this.apiService.getRequest(
      `courses/${data.CourseId}/groups/${data.GroupId}/students/topics/`,
      { context },
    );
  }

  getTopicStatistics(data: {
    CourseId: Guid;
    GroupId: Guid;
    TopicId: Guid;
  }): Observable<Array<StudentStatElement>> {
    return this.apiService.getRequest<Array<StudentStatElementDetails>>(
      `courses/${data.CourseId}/groups/${data.GroupId}/students/topics/${data.TopicId}/`,
    );
  }

  exportCourseStatistics(data: {
    CourseId: Guid;
    GroupId: Guid;
  }): Observable<string> {
    return this.apiService.getRequest(
      `courses/${data.CourseId}/groups/${data.GroupId}/students/topics/xlsx/`,
      { responseType: "blob" },
    );
  }

  getHelp(params: HelpRequest): Observable<Array<Hint>> {
    return this.apiService.getRequest<Array<Hint>>(
      `courses/${params.CourseId}/${
        params.GroupId ? `groups/${params.GroupId}/` : ""
      }exercises/${params.ExerciseId}/help/`,
    );
  }

  getVideoHelp(params: HelpRequest): Observable<Array<VideoHint>> {
    return this.apiService.getRequest<Array<VideoHint>>(
      `courses/${params.CourseId}/${
        params.GroupId ? `groups/${params.GroupId}/` : ""
      }exercises/${params.ExerciseId}/video/`,
    );
  }

  getExercise(params: ExerciseControl): Observable<Exercise> {
    return this.apiService.getRequest<Exercise>(
      `courses/${params.CourseId}/groups/${params.GroupId}/exercises/${params.ExerciseId}/`,
    );
  }

  startExercise(params: ExerciseControl): Observable<Exercise> {
    return this.apiService.patchRequest<Exercise>(
      `courses/${params.CourseId}/groups/${params.GroupId}/exercises/${params.ExerciseId}/`,
      {
        started: true,
      },
    );
  }

  startTeacherExercise(params: ExerciseControl): Observable<Exercise> {
    return this.apiService.patchRequest<Exercise>(
      `courses/${params.CourseId}/exercises/${params.ExerciseId}/`,
      {
        started: true,
      },
    );
  }

  stopExercise(params: ExerciseControl): Observable<Exercise> {
    return this.apiService.patchRequest<Exercise>(
      `courses/${params.CourseId}/groups/${params.GroupId}/exercises/${params.ExerciseId}/`,
      {
        started: false,
      },
    );
  }

  stopTeacherExercise(params: ExerciseControl): Observable<Exercise> {
    return this.apiService.patchRequest<Exercise>(
      `courses/${params.CourseId}/exercises/${params.ExerciseId}/`,
      {
        started: false,
      },
    );
  }

  selectUnselectAll(params: {
    selection: TopicSelection;
    selectionParam: ExerciseSelectionParams;
  }): Observable<Array<Exercise>> {
    return this.apiService.patchRequest<Array<Exercise>>(
      `courses/${params.selection.CourseId}/groups/${params.selection.GroupId}/topics/${params.selection.TopicId}/bulk-manage/`,
      params.selectionParam,
    );
  }

  selectUnselect(params: {
    selection: ExerciseSelection;
    selectionParam: ExerciseSelectionParams;
  }): Observable<Exercise> {
    return this.apiService.patchRequest<Exercise>(
      `courses/${params.selection.CourseId}/groups/${params.selection.GroupId}/exercises/${params.selection.exercise.id}/`,
      params.selectionParam,
    );
  }

  answer(params: AnswerPayload): Observable<Exercise> {
    let answerUrl = `courses/${params.CourseId}/${
      params.GroupId ? `groups/${params.GroupId}/` : ""
    }exercises/${params.ExerciseId}/answer/`;

    return this.apiService.postRequest<Exercise>(answerUrl, params.request);
  }

  determineActiveTopic(
    topics: Array<Topic>,
    chooseByGroup: boolean = true /* deprecated */,
  ): Topic | null {
    if (topics.length) {
      let activeTopic: Topic;

      // Check query param first
      let param: Guid = this.route.snapshot.queryParamMap.get("topicId");

      if (param) {
        const paramTopic = topics.find((topic: Topic) => topic.id === param);

        if (paramTopic) {
          return paramTopic;
        }
      }

      // Find out if any topic has started exercise inside
      const activeExerciseTopic = topics.find((topic) =>
        topic.exercises.some((exercise) => exercise.status === "started"),
      );

      if (activeExerciseTopic) {
        return activeExerciseTopic;
      }

      // Otherwise select topic by completion percentage
      const calcPercentage = (topic) =>
        topic.exercises.length
          ? Math.trunc(
              (topic.exercises.reduce(
                (acc, exercise) => acc + Number(exercise.passed),
                0,
              ) /
                topic.exercises.length) *
                100,
            )
          : 0;

      const sorted = topics
        .map((topic, index) => {
          return { topic, index };
        })
        .sort((a, b) => {
          const percentageA = calcPercentage(a.topic);
          const percentageB = calcPercentage(b.topic);

          return percentageB - percentageA || a.index - b.index;
        });

      let found = false;
      sorted.forEach((topicObj) => {
        if (!found && calcPercentage(topicObj.topic) < 100) {
          found = true;
          activeTopic = topicObj.topic;
        }
      });

      if (!found) {
        activeTopic = topics[topics.length - 1];
      }

      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: { topicId: activeTopic.id },
        queryParamsHandling: "merge",
      });

      return activeTopic;
    } else {
      return null;
    }
  }

  createImmutableExerciseMap(exercises: Array<Exercise>): ImmutableExerciseMap {
    return exercises.reduce(
      (map, exercise) =>
        map.set(exercise.id, fromJS(exercise) as ImmutableExercise),
      <ImmutableExerciseMap>OrderedMap(),
    );
  }

  modifyImmutableExerciseMap(
    map: ImmutableExerciseMap,
    exerciseData: Partial<Exercise>,
  ): ImmutableExerciseMap {
    return map.map((value) => {
      return value.mergeDeep(exerciseData);
    });
  }

  getCourseProgress(CourseId: Guid, GroupId: Guid): Observable<CourseProgress> {
    return this.apiService.getRequest<CourseProgress>(
      `courses/${CourseId}/groups/${GroupId}/progres/`,
    );
  }
}
