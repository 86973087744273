import { createReducer, on } from "@ngrx/store";
import { HttpErrorResponse } from "@angular/common/http";
import {
  destroyPartialGradesLoad,
  destroyPartialGradesLoadFailure,
  destroyPartialGradesLoadSuccess,
} from "../../actions/partial-grades/destroy-partial-grades.actions";

export interface DestroyPartialGradesState {
  error: HttpErrorResponse | null;
}

const initialState: DestroyPartialGradesState = {
  error: null,
};

export const destroyPartialGradesReducer = createReducer(
  initialState,
  on(
    destroyPartialGradesLoad,
    (state): DestroyPartialGradesState => ({
      ...state,
      error: null,
    }),
  ),
  on(
    destroyPartialGradesLoadSuccess,
    (state): DestroyPartialGradesState => ({
      ...state,
      error: null,
    }),
  ),
  on(destroyPartialGradesLoadFailure, (state, { error }) => ({
    ...state,
    error: error,
  })),
);
