import { HttpErrorResponse } from "@angular/common/http";
import { createAction, props } from "@ngrx/store";
import { PartialGradesConfig } from "src/app/shared/interfaces/student-pass-criteria-api";

export const prefix = "[Get Student Partial Grades]";

const getStudentPartialGradesActions = {
  request: `${prefix} Load`,
  requestActiveSuccess: `${prefix} Load success`,
  requestFail: `${prefix} Load fail`,
};

export const getStudentPartialGradesLoad = createAction(
  getStudentPartialGradesActions.request,
  props<{
    CourseId: string;
    GroupId: string;
  }>(),
);

export const getStudentPartialGradesLoadSuccess = createAction(
  getStudentPartialGradesActions.requestActiveSuccess,
  props<{ config: PartialGradesConfig }>(),
);

export const getStudentPartialGradesLoadFailure = createAction(
  getStudentPartialGradesActions.requestFail,
  props<{ error: HttpErrorResponse }>(),
);
