import { HttpErrorResponse } from "@angular/common/http";
import { createAction, props } from "@ngrx/store";
import {
  Config,
  CriteriaBarPayload,
} from "src/app/shared/interfaces/student-pass-criteria-api";

export const prefix = "[Delete Partial Grades]";

const deletePartialGradesActions = {
  request: `${prefix} Load`,
  requestActiveSuccess: `${prefix} Load success`,
  requestFail: `${prefix} Load fail`,
};

export const deletePartialGradesLoad = createAction(
  deletePartialGradesActions.request,
  props<{
    CourseId: string;
    GroupId: string;
    GarlicId: string;
  }>(),
);

export const deletePartialGradesLoadSuccess = createAction(
  deletePartialGradesActions.requestActiveSuccess,
);

export const deletePartialGradesLoadFailure = createAction(
  deletePartialGradesActions.requestFail,
  props<{ error: HttpErrorResponse }>(),
);
