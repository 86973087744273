<div>
  <hint class="w-100">
    {{ "COURSES.COURSE.INDIVIDUAL_GRADE_INFO" | translate }}
  </hint>

  <div class="pt-4 pb-4">
    <button
      *ngIf="!displayGradeCreator"
      [disabled]="!config"
      class="select-button"
      (click)="handleSetGarlicCreator(true)"
    >
      <icon set="uil" name="plus" />
      <span class="button-title">
        {{ "COURSES.COURSE.ADD_GRADE" | translate }}
      </span>
    </button>
  </div>

  <div class="pb-3" *ngIf="displayGradeCreator">
    <partial-grade-bar
      [creatorMode]="true"
      [isEditing]="true"
      (acceptEmitter)="
        handleCreatePartialGrade($event); handleSetGarlicCreator(false)
      "
      (cancelEmitter)="handleSetGarlicCreator(false)"
    />
  </div>

  <div class="pb-3" *ngFor="let garlic of config?.grade_configs">
    <partial-grade-bar
      [config]="garlic"
      [isEditing]="currentGarlicId === garlic.id"
      (acceptEmitter)="handleCreatePartialGrade($event)"
      (editEmitter)="handleEditGarlic(garlic.id)"
      (cancelEmitter)="setSelectedExercises($event)"
    />
  </div>
</div>
