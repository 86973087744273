import { CodeCheckListResults } from "src/app/shared/interfaces/code-check-list-results";
import { CodeVerification } from "src/app/shared/interfaces/code-verification";
import { Course } from "src/app/shared/interfaces/course";
import { CourseGroup } from "src/app/shared/interfaces/course-group";
import { Exercise } from "src/app/shared/interfaces/exercise";
import { Highlighter } from "src/app/shared/interfaces/highlighter";
import { Topic } from "src/app/shared/interfaces/topic";
import { Guid } from "src/app/shared/types/guid";
import * as fromCodeCheckHistory from "../actions/code-check-history.actions";
import * as fromQuizHistory from "../actions/quiz-history.actions";
import * as fromCourse from "../actions/course.actions";
import * as fromCourseWS from "../actions/course.websocket.actions";
import * as fromOnboarding from "../actions/onboarding.actions";
import {
  QuizSolutionItem,
  QuizSolutionsListResults,
} from "src/app/modules/quiz/interfaces/quiz-solutions-list-results";
import {
  StatElement,
  StudentStatElement,
} from "src/app/shared/interfaces/student";
import { RequestStatus } from "src/app/shared/interfaces/request-status";

export interface CourseState {
  course?: CourseGroup | Course;
  currentTopic: Topic;
  activeGroup: Guid;
  stats?: StatElement | null;
  topicStats?: Array<StudentStatElement> | null;
  getCourseFailed: boolean;
  getCourseCompleted: boolean;
  highlighter?: Highlighter | null;
  exercise: Exercise;

  codeCheckHistoryList: CodeCheckListResults;
  getCodeCheckHistoryListCompleted: boolean;
  codeCheckHistoryItem: CodeVerification;
  getCodeCheckHistoryItemCompleted: boolean;

  quizSolutionsList: QuizSolutionsListResults;
  getQuizSolutionsListCompleted: boolean;
  quizSolutionItem: QuizSolutionItem;
  getQuizSolutionItemCompleted: boolean;

  requestStatus?: RequestStatus;
  requestExportStatsStatus?: RequestStatus;
  requestStatStatus?: RequestStatus;
  requestReportGroup?: RequestStatus;
  requestReportIndividual?: RequestStatus;
  requestExerciseStatus?: RequestStatus;
}

export const initialState: CourseState = {
  currentTopic: null,
  getCourseFailed: false,
  getCourseCompleted: false,
  activeGroup: null,
  exercise: null,

  codeCheckHistoryList: null,
  getCodeCheckHistoryListCompleted: false,
  codeCheckHistoryItem: null,
  getCodeCheckHistoryItemCompleted: true,

  quizSolutionsList: null,
  getQuizSolutionsListCompleted: false,
  quizSolutionItem: null,
  getQuizSolutionItemCompleted: true,

  requestStatus: "IDLE",
  requestExportStatsStatus: "IDLE",
  requestStatStatus: "IDLE",
  requestReportGroup: "IDLE",
  requestReportIndividual: "IDLE",
  requestExerciseStatus: "IDLE",
};

export function reducer(
  state = initialState,
  action:
    | fromCourse.CourseActions
    | fromCourseWS.CourseWSActions
    | fromCodeCheckHistory.CodeCheckHistoryActions
    | fromQuizHistory.QuizSolutionsActions
    | fromOnboarding.OnboardingActions,
): CourseState {
  switch (action.type) {
    case fromCourse.GET_COURSE:
    case fromCourse.GET_COURSE_BY_GROUP:
    case fromOnboarding.GET_ONBOARDING:
      return {
        ...state,
        course: null,
        getCourseCompleted: false,
      };

    case fromCourse.GET_COURSE_COMPLETED: {
      return {
        ...state,
        course: action.payload,
        getCourseFailed: false,
        getCourseCompleted: true,
      };
    }

    case fromCourse.SET_ACTIVE_GROUP: {
      return {
        ...state,
        activeGroup: action.payload,
      };
    }

    case fromCourse.SET_CURRENT_TOPIC: {
      return {
        ...state,
        currentTopic: action.payload.Topic,
      };
    }

    case fromCourse.GET_COURSE_FAILED: {
      return {
        ...state,
        getCourseFailed: true,
      };
    }

    case fromCourse.GET_COURSE_STATISTICS: {
      return {
        ...state,
        stats: null,
        requestStatStatus: "PENDING",
      };
    }

    case fromCourse.GET_COURSE_STATISTICS_COMPLETED: {
      return {
        ...state,
        stats: action.payload,
        requestStatStatus: "SUCCESS",
      };
    }

    case fromCourse.GET_TOPIC_STATISTICS: {
      return {
        ...state,
        topicStats: null,
      };
    }

    case fromCourse.GET_TOPIC_STATISTICS_COMPLETED: {
      return {
        ...state,
        topicStats: action.payload,
      };
    }

    case fromCourse.GET_EXERCISE: {
      return {
        ...state,
        exercise: null,
        requestExerciseStatus: "PENDING",
      };
    }

    case fromCourse.GET_EXERCISE_COMPLETED: {
      return {
        ...state,
        exercise: action.payload,
        requestExerciseStatus: "SUCCESS",
      };
    }

    case fromCourse.GET_EXERCISE_FAILED: {
      return {
        ...state,
        requestExerciseStatus: "FAILED",
      };
    }

    case fromCourse.GET_EXERCISE_RESTART: {
      return {
        ...state,
        requestExerciseStatus: "IDLE",
      };
    }

    case fromCourse.GET_HELP_COMPLETED: {
      return {
        ...state,
        currentTopic: {
          ...state.currentTopic,
          exercises: state.currentTopic.exercises.map((exercise: Exercise) => {
            return exercise.id === action.payload.ExerciseId
              ? <Exercise>{ ...exercise, help: action.payload.hints }
              : exercise;
          }),
        },
        course: {
          ...state.course,
          topics: state.course.topics.map((topic: Topic) => {
            return topic.id === state.currentTopic.id
              ? {
                  ...topic,
                  exercises: topic.exercises.map((exercise: Exercise) => {
                    return exercise.id === action.payload.ExerciseId
                      ? <Exercise>{ ...exercise, help: action.payload.hints }
                      : exercise;
                  }),
                }
              : topic;
          }),
        },
      };
    }

    case fromCourse.GET_VIDEO_HELP_COMPLETED: {
      return {
        ...state,
        currentTopic: {
          ...state.currentTopic,
          exercises: state.currentTopic.exercises.map((exercise: Exercise) => {
            return exercise.id === action.payload.ExerciseId
              ? <Exercise>{ ...exercise, video_help: action.payload.video }
              : exercise;
          }),
        },
        course: {
          ...state.course,
          topics: state.course.topics.map((topic: Topic) => {
            return topic.id === state.currentTopic.id
              ? {
                  ...topic,
                  exercises: topic.exercises.map((exercise: Exercise) => {
                    return exercise.id === action.payload.ExerciseId
                      ? <Exercise>{
                          ...exercise,
                          video_help: action.payload.video,
                        }
                      : exercise;
                  }),
                }
              : topic;
          }),
        },
      };
    }

    case fromCourse.START_EXERCISE_FAILED: {
      return state.currentTopic
        ? {
            ...state,
            currentTopic: {
              ...state.currentTopic,
              exercises: state.currentTopic.exercises.map(
                (exercise: Exercise) => {
                  return exercise.id === action.payload.ExerciseId
                    ? <Exercise>{ ...exercise, status: "not-started" }
                    : exercise;
                },
              ),
            },
            course: {
              ...state.course,
              topics: state.course.topics.map((topic: Topic) => {
                return topic.id === action.payload.TopicId
                  ? {
                      ...topic,
                      exercises: topic.exercises.map((exercise: Exercise) => {
                        return exercise.id === action.payload.ExerciseId
                          ? <Exercise>{ ...exercise, status: "not-started" }
                          : exercise;
                      }),
                    }
                  : topic;
              }),
            },
          }
        : state;
    }

    case fromCourse.SELECT_UNSELECT_EXERCISE_COMPLETED: {
      return {
        ...state,
        course: {
          ...state.course,
          topics: state.course.topics.map((topic: Topic) => {
            return topic.id === action.payload.TopicId
              ? {
                  ...topic,
                  exercises: topic.exercises.map((exercise: Exercise) => {
                    return exercise.id === action.payload.exercise.id
                      ? action.payload.exercise
                      : exercise;
                  }),
                }
              : topic;
          }),
        },
        currentTopic: {
          ...state.currentTopic,
          exercises: state.currentTopic.exercises.map((exercise: Exercise) => {
            return exercise.id === action.payload.exercise.id
              ? action.payload.exercise
              : exercise;
          }),
        },
      };
    }

    case fromCourse.SELECT_UNSELECT_ALL: {
      return {
        ...state,
        course: {
          ...state.course,
          topics: state.course.topics.map((topic: Topic) => {
            return topic.id === action.payload.selection.TopicId
              ? {
                  ...topic,
                  bulkInProgress: {
                    ...topic.bulkInProgress,
                    [action.payload.selectionParamName]: true,
                  },
                }
              : topic;
          }),
        },
        currentTopic:
          state.currentTopic.id === action.payload.selection.TopicId
            ? {
                ...state.currentTopic,
                bulkInProgress: {
                  ...state.currentTopic.bulkInProgress,
                  section: action.payload.selectionParam.section,
                  [action.payload.selectionParamName]: true,
                },
              }
            : state.currentTopic,
      };
    }

    case fromCourse.SELECT_UNSELECT_ALL_COMPLETED: {
      return {
        ...state,
        course: {
          ...state.course,
          topics: state.course.topics.map((topic: Topic) => {
            return topic.id === action.payload.TopicId
              ? {
                  ...topic,
                  bulkInProgress: {
                    ...topic.bulkInProgress,
                    [action.payload.selectionParamName]: false,
                  },
                  exercises: action.payload.exercises,
                }
              : topic;
          }),
        },
        currentTopic:
          state.currentTopic.id === action.payload.TopicId
            ? {
                ...state.currentTopic,
                bulkInProgress: {
                  ...state.currentTopic.bulkInProgress,
                  [action.payload.selectionParamName]: false,
                },
                exercises: action.payload.exercises,
              }
            : state.currentTopic,
      };
    }

    case fromCourse.START_TEACHER_EXERCISE:
    case fromCourse.START_EXERCISE: {
      return {
        ...state,
        course: {
          ...state.course,
          topics: state.course.topics.map((topic: Topic) => {
            return topic.id === action.payload.TopicId
              ? {
                  ...topic,
                  exercises: topic.exercises.map((exercise: Exercise) => {
                    return exercise.id === action.payload.ExerciseId
                      ? <Exercise>{
                          ...exercise,
                          status: "starting",
                        }
                      : exercise;
                  }),
                }
              : topic;
          }),
        },
        currentTopic: {
          ...state.currentTopic,
          exercises: state.currentTopic.exercises.map((exercise: Exercise) => {
            return exercise.id === action.payload.ExerciseId
              ? <Exercise>{
                  ...exercise,
                  status: "starting",
                }
              : exercise;
          }),
        },
      };
    }

    case fromCourse.ANSWER: {
      return {
        ...state,
        requestStatus: "PENDING",
      };
    }

    case fromCourse.START_EXERCISE_COMPLETED:
    case fromCourse.STOP_EXERCISE_COMPLETED: {
      return updateExerciseState(state, action.payload);
    }

    case fromCourse.ANSWER_COMPLETED: {
      return updateExerciseState(state, action.payload, {
        requestStatus: "SUCCESS",
      });
    }

    case fromCourse.ANSWER_COMPLETED_WRONG: {
      return {
        ...state,
        currentTopic: {
          ...state.currentTopic,
          exercises: state.currentTopic.exercises.map((exercise: Exercise) => {
            return exercise.id === action.payload.exercise.id
              ? { ...exercise, passed: false }
              : exercise;
          }),
        },
        requestStatus: "FAILED",
      };
    }

    case fromCourse.DOWNLOAD_GROUP_REPORT: {
      return {
        ...state,
        requestReportGroup: "PENDING",
      };
    }

    case fromCourse.DOWNLOAD_GROUP_REPORT_SUCCESS: {
      return {
        ...state,
        requestReportGroup: "SUCCESS",
      };
    }

    case fromCourse.DOWNLOAD_GROUP_REPORT_FAILED: {
      return {
        ...state,
        requestReportGroup: "FAILED",
      };
    }

    case fromCourse.DOWNLOAD_GROUP_REPORT_RESTART: {
      return {
        ...state,
        requestReportGroup: "IDLE",
      };
    }

    case fromCourse.DOWNLOAD_INDIVIDUAL_REPORT: {
      return {
        ...state,
        requestReportIndividual: "PENDING",
      };
    }

    case fromCourse.DOWNLOAD_INDIVIDUAL_REPORT_SUCCESS: {
      return {
        ...state,
        requestReportIndividual: "SUCCESS",
      };
    }

    case fromCourse.DOWNLOAD_INDIVIDUAL_REPORT_FAILED: {
      return {
        ...state,
        requestReportIndividual: "FAILED",
      };
    }

    case fromCourseWS.UPDATE_TOPIC: {
      return state.currentTopic && state.activeGroup === action.payload.groupId
        ? {
            ...state,
            currentTopic:
              action.payload.id === state.currentTopic.id
                ? { ...state.currentTopic, exercises: action.payload.exercises }
                : state.currentTopic,
            course: {
              ...state.course,
              topics: state.course.topics.map((topic: Topic) => {
                return topic.id === action.payload.id
                  ? { ...topic, exercises: action.payload.exercises }
                  : topic;
              }),
            },
          }
        : state;
    }

    case fromCourseWS.HIGHLIGHT_EXERCISE: {
      return {
        ...state,
        highlighter: action.payload,
      };
    }

    case fromCourseWS.UPDATE_USER_VPN_CONNECTION_STATUS: {
      return state.topicStats
        ? {
            ...state,
            topicStats: state.topicStats.map((stat) =>
              stat.email === action.payload.email
                ? { ...stat, remote_ip: action.payload.remote_ip }
                : stat,
            ),
          }
        : state;
    }

    case fromCodeCheckHistory.GET_CODE_CHECK_LIST: {
      return {
        ...state,
        getCodeCheckHistoryListCompleted: false,
        getCodeCheckHistoryItemCompleted: true,
        codeCheckHistoryItem: null,
      };
    }

    case fromCodeCheckHistory.GET_CODE_CHECK_LIST_COMPLETED: {
      return {
        ...state,
        codeCheckHistoryList: action.payload,
        getCodeCheckHistoryListCompleted: true,
      };
    }

    case fromCodeCheckHistory.GET_CODE_CHECK_LIST_PAGE_COMPLETED: {
      return {
        ...state,
        codeCheckHistoryList: {
          ...action.payload,
          results: state.codeCheckHistoryList.results.concat(
            action.payload.results,
          ),
        },
      };
    }

    case fromCodeCheckHistory.GET_CODE_CHECK: {
      return {
        ...state,
        getCodeCheckHistoryItemCompleted: false,
      };
    }

    case fromCodeCheckHistory.GET_CODE_CHECK_COMPLETED: {
      return {
        ...state,
        codeCheckHistoryItem: action.payload,
        getCodeCheckHistoryItemCompleted: true,
      };
    }

    case fromQuizHistory.GET_QUIZ_SOLUTIONS_LIST: {
      return {
        ...state,
        getQuizSolutionsListCompleted: false,
        getQuizSolutionItemCompleted: true,
        quizSolutionItem: null,
      };
    }

    case fromQuizHistory.GET_QUIZ_SOLUTIONS_LIST_COMPLETED: {
      return {
        ...state,
        quizSolutionsList: action.payload,
        getQuizSolutionsListCompleted: true,
      };
    }

    case fromQuizHistory.GET_QUIZ_SOLUTIONS_LIST_PAGE_COMPLETED: {
      return {
        ...state,
        quizSolutionsList: {
          ...action.payload,
          results: state.quizSolutionsList.results.concat(
            action.payload.results,
          ),
        },
      };
    }

    case fromQuizHistory.GET_QUIZ_SOLUTION: {
      return {
        ...state,
        getQuizSolutionItemCompleted: false,
      };
    }

    case fromQuizHistory.GET_QUIZ_SOLUTION_COMPLETED: {
      return {
        ...state,
        quizSolutionItem: action.payload,
        getQuizSolutionItemCompleted: true,
      };
    }

    case fromCourse.EXPORT_COURSE_STATISTICS: {
      return {
        ...state,
        requestExportStatsStatus: "PENDING",
      };
    }

    case fromCourse.EXPORT_COURSE_STATISTICS_COMPLETED: {
      return {
        ...state,
        requestExportStatsStatus: "SUCCESS",
      };
    }

    case fromCourse.EXPORT_COURSE_STATISTICS_FAILED: {
      return {
        ...state,
        requestExportStatsStatus: "FAILED",
      };
    }

    default:
      return state;
  }
}

function updateExerciseState(
  state: CourseState,
  payload: { exercise: Exercise; TopicId: Guid; GroupId?: Guid },
  additionalState: Partial<CourseState> = {},
): CourseState {
  if (
    !state.course ||
    !state.currentTopic ||
    (payload.GroupId && state.activeGroup !== payload.GroupId)
  ) {
    return state;
  }

  return {
    ...state,
    course: {
      ...state.course,
      topics: state.course.topics.map((topic: Topic) =>
        topic.id === payload.TopicId
          ? {
              ...topic,
              exercises: topic.exercises.map((exercise: Exercise) =>
                exercise.id === payload.exercise.id
                  ? {
                      ...exercise,
                      status: payload.exercise.status,
                      passed: payload.exercise.passed,
                      description: payload.exercise.description,
                      mark: payload.exercise.mark,
                      can_start: payload.exercise.can_start,
                    }
                  : exercise,
              ),
            }
          : topic,
      ),
    },
    currentTopic: {
      ...state.currentTopic,
      exercises: state.currentTopic.exercises.map((exercise: Exercise) =>
        exercise.id === payload.exercise.id
          ? {
              ...exercise,
              status: payload.exercise.status,
              passed: payload.exercise.passed,
              description: payload.exercise.description,
              mark: payload.exercise.mark,
              can_start: payload.exercise.can_start,
            }
          : exercise,
      ),
    },
    ...additionalState,
  };
}
